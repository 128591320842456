import React, { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import { useAuthenticate } from "../../Context/AuthContext";
import axios from "axios";
import { databaseUrl } from "../../utils/data";
import { toast } from "react-toastify";

const AddNewCustomerModal = ({ setAddCustomerModal,getCustomersList }) => {
  const {authToken} = useAuthenticate()
  const [customerData, setCustomerData] = useState({});
  const [addingCustomer, setAddingCustomer] = useState(false);

  const handleFormChange = (e) => {
    setCustomerData({
      ...customerData,
      [e.target.name]: e.target.value,
    });
  };

  const createCustomer = async (e) => {
    e.preventDefault();
    setAddingCustomer(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authToken}`,
      },
    };

    try {
      await axios.post(`${databaseUrl}/sales/customers/`, customerData, config).then((response)=>{
        Swal.fire({
          title: "Created!",
          text: "Customer has been created.",
          icon: "success",
        }).then(() => {
          setAddingCustomer(false);
          setAddCustomerModal(false);
          getCustomersList()
        });
      })
    } catch (error) {
      
      setAddingCustomer(false);
      toast.error("Something went wrong!")
    }
  };
  return (
    <div className="modal fade" id="add-customer">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add new customer</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                onClick={() => setAddCustomerModal(false)}
              >
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={(e) => createCustomer(e)}>
              <div className="row">
                <div className="col-12 col-lg-12">
                  <div className="form-group">
                    <label className="input-label">
                      Full name
                      <span className="input-label-secondary text-danger">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      name="customer_name"
                      className="form-control"
                      placeholder="First name"
                      required
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                </div>
                
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <label className="input-label">Email</label>
                    <input
                      type="email"
                      name="customer_email"
                      className="form-control"
                      placeholder="Ex : ex@example.com"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <label className="input-label">
                      Phone (With country code)
                      <span className="input-label-secondary text-danger">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      name="customer_phone_number"
                      className="form-control"
                      placeholder="Phone"
                      required
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="btn--container justify-content-end">
                {!addingCustomer ? (
                  <>
                    <button type="reset" className="btn btn--reset">
                      Reset
                    </button>
                    <button
                      type="submit"
                      id="submit_new_customer"
                      className="btn btn--primary"
                    >
                      Submit
                    </button>
                  </>
                ) : (
                  <RotatingLines
                    visible={true}
                    height="96"
                    width="96"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewCustomerModal;
