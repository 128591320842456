import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { databaseUrl } from "../../utils/data";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useAuthenticate } from "../../Context/AuthContext";

const SystemSettings = () => {
    const {pharmacySetup, authToken} = useAuthenticate()
    const [setupUpdateData, setSetupUpdateData] = useState({})
    const [updatingDetails, setUpdatingDetails] = useState(false)
    const [activeTab, setActiveTab] = useState(1);

    const updateDate = (e) =>{
        setSetupUpdateData({
            ...setupUpdateData, [e.target.name]:e.target.value
        })
    }

    const updateSetup = async (e) => {
        e.preventDefault()
        setUpdatingDetails(true);
        const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${authToken}`,
            },
          };
        
          const body = JSON.stringify(setupUpdateData)
        await axios.post(`${databaseUrl}/account/pharmacy-setup/`,body, config).then((response)=>{
            Swal.fire({
                icon:"success",
                title:"Success",
                text:"Setup updated successfully"
            }).then(()=>{
                window.location.reload();
            })
        }).catch((error) => {
            setUpdatingDetails(false);
            if(error?.response?.status === 500){
                return toast.error("Internal server error");
              }
              if(error?.response?.status === 400){
                for(const property in error.response.data){
                  if(property !== "0"){
                    toast.error(`${property} error: ${error.response.data[property]}`)
                  }else{
                    toast.error(`${error.response.data[property]}`)
                  }
                }
              }else{
                toast.error("Something went wrong");
              }
        })
    }

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span>System Setup</span>
          </h1>
          <ul className="nav nav-tabs border-0 mb-3">
            <li className="nav-item">
              <a
                href="#"
              className={`nav-link ${activeTab === 1 && "active"}`}
                onClick={()=>setActiveTab(1)}
              >
                Medicine Setup
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content">
          <div className="tab-pane fade show active" id="business-setting">
            <div className="card">
              <div className="card-body">
                <form
                  method="post"
                  onSubmit={(e)=>updateSetup(e)}
                >
                  <div className="row align-items-end">
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <label
                          className="input-label"
                          htmlFor="minimum_stock_limit"
                        >
                          Minimum stock limit (in quantity)
                        </label>
                        <input
                          type="number"
                          min={1}
                          value={
                            setupUpdateData?.minimum_stock_limit ? setupUpdateData?.minimum_stock_limit : pharmacySetup?.minimum_stock_limit
                          }
                          name="minimum_stock_limit"
                          className="form-control"
                          placeholder=""
                          required
                          onChange={(e)=>updateDate(e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                        <label
                          className="input-label"
                          htmlFor="minimum_expiry_limit"
                        >
                          Days Left To Expiry (in days)
                        </label>
                        <input
                          type="number"
                          min={1}
                          value={
                            setupUpdateData?.minimum_expiry_limit ? setupUpdateData?.minimum_expiry_limit : pharmacySetup?.minimum_expiry_limit
                          }
                          name="minimum_expiry_limit"
                          className="form-control"
                          placeholder=""
                          required
                          onChange={(e)=>updateDate(e)}
                        />
                      </div>
                    </div>
                    
                  </div>
                  {
                    updatingDetails ? (
                        <div className="d-flex justify-content-end">
                            <RotatingLines
                                visible={true}
                                height="96"
                                width="96"
                                strokeColor="blue"
                                strokeWidth="5"
                                animationDuration="0.75"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                        </div>
                    ):(
                  <div className="btn--container justify-content-end">
                    <button type="reset" className="btn btn--reset">
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="btn btn--primary call-demo"
                    >
                      Save
                    </button>
                  </div>
                    )
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </main>
  );
};

export default SystemSettings;
