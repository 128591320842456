import React, { useState } from "react";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import { useAuthenticate } from "../../Context/AuthContext";
import { databaseUrl } from "../../utils/data";
import { toast } from "react-toastify";
import axios from "axios";

const AddDrug = () => {
  const { categoriesList, authToken } = useAuthenticate();
  const [data, setData] = useState({
    manufacturer_price: 0,
    final_price: 0,
    markup_rate: 0,
  });
  const [addingMedicine, setAddingMedicine] = useState(false);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const calculateFinalPrice = (manufacturer_price, markup_rate) => {
    if (manufacturer_price !== "") {
      let tax_amount = parseFloat(manufacturer_price) * (parseFloat(markup_rate) / 100);
      
      let final_price = parseFloat(parseFloat(manufacturer_price) + tax_amount).toFixed(2);
      
      setData({
        ...data,
        ["manufacturer_price"]: manufacturer_price,
        ["markup_rate"]: markup_rate,
        ["final_price"]: final_price,
      });
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setAddingMedicine(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${authToken}`,
      },
    };

    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    try {
      await axios
        .post(`${databaseUrl}/inventory/medicine/`, formData, config)
        .then((response) => {
          if (response.status === 201) {
            Swal.fire({
              title: "Created!",
              text: "Medicine has been created.",
              icon: "success",
            }).then(() => {
              setAddingMedicine(false);
              window.location.reload();
            });
          }
        });
    } catch (error) {
      
      toast.error("Something went wrong");
      setAddingMedicine(false);
    }
  };
  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/add-product.png"
                className="w--24"
                alt=""
              />
            </span>
            <span> Add New Medicine </span>
          </h1>
        </div>
        <form
          onSubmit={(e) => submitForm(e)}
          id="product_form"
          className="row g-2"
        >
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body pt-2">
                <ul className="nav nav-tabs mb-4">
                  <li className="nav-item">
                    <a
                      className="nav-link lang_link active"
                      
                      id="en-link"
                    >
                      Medicine Details
                    </a>
                  </li>
                </ul>
                <div className="lang_form" id="en-form">
                  <div className="form-group">
                    <label className="input-label" htmlFor="en_name">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="en_name"
                      className="form-control"
                      placeholder="New Product"
                      required
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>

                  <div className="form-group">
                    <label className="input-label" htmlFor="en_name">
                      Generic Name
                    </label>
                    <input
                      type="text"
                      name="generic_name"
                      id="en_generic_name"
                      className="form-control"
                      placeholder="New Product"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>

                  <div className="form-group mb-0">
                    <label className="input-label" htmlFor="en_description">
                      Short Description
                    </label>
                    <textarea
                      name="description"
                      className="form-control h--172px"
                      id="en_hiddenArea"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            {/* CATEGORY CARD */}
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">
                  <span className="card-header-icon">
                    <i className="tio-user" />
                  </span>
                  <span> Category </span>
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label
                        className="input-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Category<span className="input-label-secondary">*</span>
                      </label>
                      <select
                        name="category"
                        className="form-control js-select2-custom"
                        onChange={(e) => handleFormChange(e)}
                      >
                        <option value="">---Select---</option>
                        {categoriesList.map((category, index) => (
                          <option value={category.id} key={index}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* MEDICINE IMAGE CARD */}
            <div className="card mt-3">
              <div className="card-body">
                <h5 className="mb-3">
                  Medicine Image
                </h5>
                <div className="product--coba">
                  <div className="">
                    <input
                      type="file"
                      name="medicine_image"
                      accept=".jpg, .png, .jpeg, .webp"
                      onChange={(e) =>
                        setData({
                          ...data,
                          [e.target.name]: e.target.files[0],
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">
                  <span className="card-header-icon">
                    <i className="tio-dollar" />
                  </span>
                  <span> Price information </span>
                </h5>
              </div>
              <div className="card-body">
                <div className="p-2">
                  <div className="row g-3">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Medicine Type
                          <span className="input-label-secondary">*</span>
                        </label>
                        <select
                          name="medicine_type"
                          className="form-control js-select2-custom"
                          onChange={(e) => handleFormChange(e)}
                        >
                          <option value="">---Select---</option>
                          <option value="Local">Local</option>
                          <option value="Foreign">Foreign</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Manufacturer's price
                        </label>
                        <input
                          type="number"
                          min={0}
                          max={100000000}
                          step="any"
                          // value={data?.manufacturer_price}
                          name="manufacturer_price"
                          className="form-control"
                          placeholder="Ex : 349"
                          required
                          onChange={(e) => {
                            // handleFormChange(e);
                            calculateFinalPrice(e.target.value, data?.markup_rate);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Product stock
                        </label>
                        <input
                          type="number"
                          min={0}
                          max={100000000}
                          name="quantity"
                          className="form-control"
                          placeholder="Ex : 100"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Markup <span id="tax_symbol">(%)</span>
                        </label>
                        <input
                          type="number"
                          min={0}
                          // value={data?.markup_rate}
                          step="0.01"
                          max={100000}
                          name="markup_rate"
                          className="form-control"
                          placeholder="Ex : 10"
                          required
                          onChange={(e) => {
                            handleFormChange(e);
                            calculateFinalPrice(
                              data?.manufacturer_price,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Final Unit price
                        </label>
                        <input
                          type="number"
                          min={0}
                          max={100000000}
                          step="any"
                          name="final_price"
                          className="form-control"
                          disabled
                          required
                          value={data?.final_price}
                        />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group mb-0">
                        <label
                          className="input-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Expiry Date
                        </label>
                        <input
                          type="date"
                          name="expiry_date"
                          className="form-control"
                          required
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="btn--container justify-content-end">
              {!addingMedicine ? (
                <>
                  <a href="" className="btn btn--reset min-w-120px">
                    Reset
                  </a>
                  <button type="submit" className="btn btn--primary">
                    Submit
                  </button>
                </>
              ) : (
                <RotatingLines
                  visible={true}
                  height="96"
                  width="96"
                  color="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default AddDrug;
