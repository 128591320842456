import axios from 'axios'
import React, { useState } from 'react'
import { RotatingLines } from 'react-loader-spinner'
import { databaseUrl } from '../../utils/data'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { useAuthenticate } from '../../Context/AuthContext'

const NewExpenseModal = ({setRecordExpenseModal, expenseTypes}) => {
    const [recordingExpense, setRecordingExpense] = useState( )
    const [newExpenseData, setNewExpenseData] = useState({})
    const {authToken} = useAuthenticate()
    const handleFormChange = (e) => {
        setNewExpenseData({
          ...newExpenseData,
          [e.target.name]: e.target.value,
        });
      };

    const recordExpense = async (e) =>{
        e.preventDefault()
        setRecordingExpense(true)
        
        const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${authToken}`,
            },
          };
          const body = JSON.stringify(
            newExpenseData
          )
          await axios.post(`${databaseUrl}/report/expense-overview/`,body, config).then((response)=>{
            setRecordingExpense(false)
            Swal.fire({
                title:"Recorded!",
                text:"Expense has been recorded successfully",
                icon: "success"
            }).then(()=>{
                window.location.reload()
            })
          }).catch((error)=>{
            console.error(error)
            setRecordingExpense(false)
            toast.error("Something went wrong!")
          })
    }
    return (
    <div className="modal fade" id="add-customer">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add new expense</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                onClick={() => setRecordExpenseModal(false)}
              >
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={(e) => recordExpense(e)}>
              <div className="row">
                <div className="col-12 col-lg-12">
                  <div className="form-group">
                    <label className="input-label">
                      Expense Type
                      <span className="input-label-secondary text-danger">
                        *
                      </span>
                    </label>
                    <select name="expense_type" onChange={(e) => handleFormChange(e)} className="form-control" id="">
                        <option value="">Select Expense Type</option>
                            {
                            expenseTypes.map((expenseType, index) => (
                                <option key={index} value={expenseType.id}>{expenseType.name}</option>
                                ))
                            }
                            <option value="Other">Other</option>
                    </select>
                    
                  </div>
                </div>
                {
                    newExpenseData?.expense_type === "Other" && (
                        <div className="col-12 col-lg-12">
                        <div className="form-group">
                            <label className="input-label">New Expense Type Name <span className="input-label-secondary text-danger">
                                *
                            </span></label>
                            <input
                            type="text"
                            name="new_expense_type"
                            className="form-control"
                            required
                            onChange={(e) => handleFormChange(e)}
                            />
                        </div>
                        </div>
                    )
                }
                
              </div>
              <div className="row">
                <div className="col-12 col-lg-12">
                  <div className="form-group">
                    <label className="input-label">Amount <span className="input-label-secondary text-danger">
                        *
                      </span></label>
                    
                    <input
                      type="number"
                      step="0.01"
                      name="amount"
                      className="form-control"
                      required
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-12">
                  <div className="form-group">
                    <label className="input-label">
                      Description
                      <span className="input-label-secondary text-danger">
                        *
                      </span>
                    </label>
                    <textarea
                      type="text"
                      name="description"
                      className="form-control"
                      required
                      onChange={(e) => handleFormChange(e)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="btn--container justify-content-end">
                {!recordingExpense ? (
                  <>
                    <button type="reset" className="btn btn--reset">
                      Reset
                    </button>
                    <button
                      type="submit"
                      id="submit_new_customer"
                      className="btn btn--primary"
                    >
                      Submit
                    </button>
                  </>
                ) : (
                  <RotatingLines
                    visible={true}
                    height="96"
                    width="96"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewExpenseModal