import React, { useEffect, useState } from "react";
import { useAuthenticate } from "../../Context/AuthContext";
import { databaseUrl } from "../../utils/data";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";

const EmployeeDetails = () => {
  const { employee_id } = useParams();
  const { authToken } = useAuthenticate();
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [employeeUpdateDetails, setEmployeeUpdateDetails] = useState(null);
  const [updatingEmployee, setUpdatingEmployee] = useState(false);

  const handleFormChange = (e) => {
    setEmployeeUpdateDetails({
      ...employeeUpdateDetails,
      [e.target.name]: e.target.value,
    });
  };

  const getEmployeeDetails = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authToken}`,
      },
    };

    await axios
      .get(`${databaseUrl}/employee/${employee_id}/details/`, config)
      .then((response) => {
        setEmployeeDetails(response.data);
      })
      .catch((error) => {
        toast.error("An unexpected error occured.");
      });
  };

  const updateEmployee = async (e) => {
    e.preventDefault();
    setUpdatingEmployee(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${authToken}`,
      },
    };

    const formData = new FormData();

    Object.keys(employeeUpdateDetails).forEach((key) => {
      formData.append(key, employeeUpdateDetails[key]);
    });

    await axios
      .put(`${databaseUrl}/employee/${employee_id}/details/`, formData, config)
      .then((response) => {
        setUpdatingEmployee(false);
        Swal.fire({
          icon: "success",
          title: "Employee updated",
          text: "Employee's details has been updated successfully",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        setUpdatingEmployee(false);
        toast.error("An unexpected error occured.");
      });
  };

  useEffect(() => {
    getEmployeeDetails();
  }, []);
  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/employee.png"
                className="w--24"
                alt="mail"
              />
            </span>
            <span> Update Employee Details </span>
          </h1>
        </div>
        <form onSubmit={(e) => updateEmployee(e)} style={{ textAlign: "left" }}>
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">
                <span className="card-header-icon">
                  <i className="tio-user" />
                </span>
                <span>General Information</span>
              </h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="row g-3">
                    <div className="col-md-12">
                      <label className="form-label">Full Name</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        name="full_name"
                        className="form-control"
                        id="name"
                        placeholder="Ex : John Doe"
                        onChange={(e) => handleFormChange(e)}
                        required
                        value={
                          employeeUpdateDetails?.full_name
                            ? employeeUpdateDetails?.full_name
                            : employeeDetails?.full_name
                        }
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Phone</label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        name="phone_number"
                        defaultValue=""
                        className="form-control"
                        id="phone"
                        placeholder="Ex : +233********"
                        onChange={(e) => handleFormChange(e)}
                        required
                        value={
                          employeeUpdateDetails?.phone_number
                            ? employeeUpdateDetails?.phone_number
                            : employeeDetails?.phone_number
                        }
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Role</label>
                      <span className="text-danger">*</span>
                      <select
                        className="form-control"
                        name="role"
                        style={{ maxWidth: "100%" }}
                        onChange={(e) => handleFormChange(e)}
                      >
                        <option value="" disabled>
                          ---Select---
                        </option>
                        <option
                          selected={employeeDetails?.role === "Pharmacist"}
                          value="Pharmacist"
                        >
                          Pharmacist
                        </option>
                        <option
                          selected={employeeDetails?.role === "Administrator"}
                          value="Administrator"
                        >
                          Administrator
                        </option>
                        {/* <option selected={employeeDetails?.role === "Manager"} value="Manager">Manager</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label d-none d-md-block">
                      {" "}
                      &nbsp;{" "}
                    </label>
                    <center className="mb-4">
                      {employeeUpdateDetails?.profile_picture ? (
                        <img
                          className="initial-24"
                          id="viewer"
                          src={URL.createObjectURL(
                            employeeUpdateDetails?.profile_picture
                          )}
                          alt="Employee thumbnail"
                        />
                      ) : (
                        <img
                          className="initial-24"
                          id="viewer"
                          src={
                            employeeDetails?.profile_picture
                              ? `https://api.stewardspharmacy.com/${employeeDetails?.profile_picture}`
                              : "https://grofresh-admin.6amtech.com/public/assets/admin/img/upload-vertical.png"
                          }
                          alt="Employee thumbnail"
                        />
                      )}
                    </center>
                    <div className="form-group mb-0">
                      <label className="form-label d-block">
                        Employee Image
                      </label>
                      <div>
                        <input
                          type="file"
                          name="profile_picture"
                          // className="custom-file-input h--45px"
                          accept=".jpg, .png, .jpeg"
                          onChange={(e) => {
                            setEmployeeUpdateDetails({
                              ...employeeUpdateDetails,
                              [e.target.name]: e.target.files[0],
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mt-3">
            <div className="card-header">
              <h5 className="card-title">
                <span className="card-header-icon">
                  <i className="tio-user" />
                </span>
                <span>Account Information</span>
              </h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-md-4 col-sm-6">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    name="email"
                    defaultValue=""
                    className="form-control"
                    id="email"
                    placeholder="Ex : ex@gmail.com"
                    required
                    onChange={(e) => handleFormChange(e)}
                    value={
                      employeeUpdateDetails?.email
                        ? employeeUpdateDetails?.email
                        : employeeDetails?.email
                    }
                  />
                </div>
                <div className="col-md-4 col-sm-6">
                  <label className="form-label">Username</label>
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    id="username"
                    placeholder="Ex : 8+ Characters"
                    disabled
                    value={employeeDetails?.username}
                  />
                </div>
                <div className="col-md-4 col-sm-6">
                  <label className="form-label">Password</label>
                  <input
                    type="text"
                    name="password"
                    className="form-control"
                    id="password"
                    placeholder="*****"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="btn--container justify-content-end mt-3">
            {!updatingEmployee ? (
              <>
                <button type="submit" className="btn btn--primary">
                  Update
                </button>
              </>
            ) : (
              <RotatingLines
                visible={true}
                height="96"
                width="96"
                color="grey"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            )}
          </div>
        </form>
      </div>
    </main>
  );
};

export default EmployeeDetails;
