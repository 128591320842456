import React, { useRef, useState } from "react";
import { databaseUrl } from "../../utils/data";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { useAuthenticate } from "../../Context/AuthContext";
import ReactToPrint from "react-to-print";

const AttendanceDetails = () => {
  const {authToken} = useAuthenticate()
  const attendanceListRef = useRef()
  const [attendanceList, setAttendanceList ] = useState([])
  const [filterDate, setFilterDate] = useState("")
  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = attendanceList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(attendanceList.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % attendanceList.length;
    setItemOffset(newOffset);
  };

  const getAttendanceReport = async (e) => {
    e.preventDefault()
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authToken}`,
      },
    };

    await axios.get(`${databaseUrl}/attendance/report/${filterDate}/`, config).then((response)=>{
      setAttendanceList(response.data);

    }).catch((error)=>{
      console.clear()
    })
  }

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="mb-0 page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/all_orders.png"
                className="w--20"
                alt=""
              />
            </span>
            <span className="">Attendance Details</span>
          </h1>
        </div>
        <div className="card">
          <div className="card-header shadow flex-wrap p-20px border-0">
            <h5 className="form-bold w-100 mb-3">Select Date Range</h5>
            <form className="w-100 mt-5" onSubmit={(e)=>getAttendanceReport(e)}>
              <div className="row g-3 g-sm-4 g-md-3 g-lg-4">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="input-date-group">
                    <label className="input-label">Attendance Date</label>
                    <label className="w-100">
                      <input
                        type="date"
                        id="start_date"
                        name="start_date"
                        onChange={(e)=>setFilterDate(e.target.value)}
                        required
                        className="form-control min-h-45px"
                      />
                    </label>
                  </div>
                </div>

                <div className="col-sm-6 col-md-4 col-lg-2">
                  <button
                    type="submit"
                    id="show_filter_data"
                    className="btn w-100 btn--primary min-h-45px p-2"
                  >
                    Filter
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="card-body p-20px">
            <div className="order-top">
              <div className="card--header">
                <ReactToPrint trigger={()=>(
                <button className="btn btn-primary">
                  Print List
                </button>

                )}
                  content={()=>attendanceListRef.current}
                />
              </div>
            </div>
            <div className="table-responsive datatable-custom">
              <table
                className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                style={{ width: "100%" }}
                ref={attendanceListRef}
              >
                <thead className="thead-light">
                  <tr>
                    <th className="">SL</th>
                    <th>Employee</th>
                    <th>Date</th>
                    <th className="text-center">Check In</th>
                    <th>
                      <div className="text-center">Check Out</div>
                    </th>
                  </tr>
                </thead>
                <tbody id="set-rows">
                  {currentItems.map((attendance, index) => (
                    <tr key={index} className="status-delivered class-all">
                      <td className="">{index + 1}</td>
                      <td className="text-capitalize">
                        <div className="table--media">
                          
                          <div className="table--media-body">
                            <h5 className="title">{attendance.employee}</h5>
                          </div>
                        </div>
                      </td>
                      <td>{attendance.date}</td>
                      <td>
                        <div className="text-center">
                          {attendance.check_in_time}
                        </div>
                      </td>
                      <td>
                        <div className="text-center">
                          {attendance.check_out_time}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer border-0">
            <div className="d-flex justify-content-center justify-content-sm-end">
              <nav>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="›"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="‹"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  activeClassName="page-item active"
                  pageLinkClassName="page-link"
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AttendanceDetails;
