import React, { useState } from 'react'
import Topbar from './Topbar'
import Sidebar from './Sidebar'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../Pages/Dashboard/Dashboard'
import MedicineList from '../Pages/Medicine/MedicineList'
import Footer from './Footer'
import AddDrug from '../Pages/Medicine/AddDrug'
import Categories from '../Pages/Medicine/Categories'
import SalesHistory from '../Pages/Sales/SalesHistory'
import SaleDetails from '../Pages/Sales/SaleDetails'
import SaleReceipt from '../Pages/Sales/SaleReceipt'
import NewSale from '../Pages/Sales/NewSale'
import Employees from '../Pages/StaffManagement/Employees'
import NewEmployee from '../Pages/StaffManagement/NewEmployee'
import RevenueReport from '../Pages/Reports/RevenueReport'
import ExpenseReport from '../Pages/Reports/ExpenseReport'
import CustomerList from '../Pages/Customers/CustomerList'
import MedicineDetails from '../Pages/Medicine/MedicineDetails'
import CategoryDetails from '../Pages/Medicine/CategoryDetails'
import AttendanceReport from '../Pages/StaffManagement/AttendanceReport'
import AttendanceDetails from '../Pages/StaffManagement/AttendanceDetails'
import RevenueDetails from '../Pages/Reports/RevenueDetails'
import Profile from '../Pages/Settings/Profile'
import AddCustomer from '../Pages/Customers/AddCustomer'
import CustomerSales from '../Pages/Customers/CustomerSales'
import CustomerDebts from '../Pages/Customers/CustomerDebts'
import PaymentReceipt from '../Pages/Sales/PaymentReceipt'
import IncomeStatement from '../Pages/Reports/IncomeStatement'
import EmployeeDetails from '../Pages/StaffManagement/EmployeeDetails'
import SystemSettings from '../Pages/Settings/SystemSettings'

const Layout = () => {
    const [openSidebar, setOpenSidebar] = useState(true)
  return (
    <div className='footer-offset'>
        <Topbar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
        <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            {/* MEDICINE */}
            <Route path='/medicine/list' element={<MedicineList />} />
            <Route path="/medicine/add" element={<AddDrug />} />
            <Route path="/medicine/:medicine_id/details" element={<MedicineDetails />} />
            <Route path="/medicine/categories" element={<Categories /> } />
            <Route path="/medicine/categories/:category_id" element={<CategoryDetails />} />
            {/* SALES */}
            <Route path='/sales/new' element={<NewSale />} />
            <Route path="/sales/list" element={<SalesHistory />} />
            <Route path="/sales/:sale_id/details" element={<SaleDetails />} />
            <Route path="/sales/:sale_id/receipt" element={<SaleReceipt />} />
            <Route path="/sales/payment/:payment_id/receipt" element={<PaymentReceipt />} />
            
            {/* STAFF MANAGEMENT */}
            <Route path="/staff-management/employees" element={<Employees />} />
            <Route path="/staff-management/employees/new" element={<NewEmployee />} />
            <Route path="/staff-management/staff-attendance-report/" element={<AttendanceReport />} />
            <Route path="/staff-management/attendance-details/" element={<AttendanceDetails />} />
            
            <Route path="/staff-management/employees/:employee_id" element={<EmployeeDetails />} />
            {/* REPORTS */}
            <Route path='/reports/revenue' element={<RevenueReport />} />
            <Route path="/reports/expense" element={<ExpenseReport />} />
            <Route path='/reports/revenue/:revenue_date' element={<RevenueDetails />} />
            <Route path="/reports/income-statement" element={<IncomeStatement />} />
            {/* CUSTOMERS */}
            <Route path="/customers/list" element={<CustomerList />} />
            <Route path="/customers/new" element={<AddCustomer />} />
            <Route path="/customer-sales/:customer_id" element={<CustomerSales />} />
            <Route path="/customers/debts/" element={<CustomerDebts />} />

            {/* SETTINGS */}
            <Route path="/settings/profile" element={<Profile />} />
            <Route path='/settings/system-setup' element={<SystemSettings />} />

            
        </Routes>
        <Footer />
    </div>
  )
}

export default Layout