import React, { useEffect, useRef, useState } from "react";
import { useAuthenticate } from "../../Context/AuthContext";
import ReactToPrint from "react-to-print";
import axios from "axios";
import { databaseUrl } from "../../utils/data";
import Loading from "../../Components/Loading";
import { Link, useParams } from "react-router-dom";
import PharmacyLogo from "../../assets/stewards1.png"

const PaymentReceipt = () => {
  const { payment_id } = useParams();
  const currentYear = new Date().getFullYear();
  const currentDate = new Date();
  const { authToken } = useAuthenticate();
  const [paymentDetails, setPaymentDetails] = useState();
  const [loadingDetails, setLoadingDetails] = useState(true);
  const receiptRef = useRef();

  const dateOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const getPaymentDetails = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authToken}`,
      },
    };

    await axios
      .get(`${databaseUrl}/sales/payment-details/${payment_id}`, config)
      .then((response) => {
        setPaymentDetails(response.data);
        setLoadingDetails(false);
      })
      .catch((error) => {
        setLoadingDetails(false);
        
      });
  };

  useEffect(() => {
    getPaymentDetails();
  }, []);

  if (loadingDetails) {
    return <Loading />;
  } else {
    return (
      <main id="content" role="main" className="main main-page pointer-event">
        <div className="content container-fluid initial-38">
          <div className="row justify-content-center" id="printableArea">
            <div className="col-md-12">
              <center>
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn--primary non-printable text-white mr-2">
                      Print Receipt
                    </button>
                  )}
                  content={() => receiptRef.current}
                />
                <Link
                  to="/sales/new"
                  className="btn btn--danger non-printable text-white"
                >
                  Back
                </Link>
              </center>
              <hr className="non-printable" />
            </div>
            {/* RECEIPT */}
            <div className="initial-38-1" ref={receiptRef}>
              <div className="pt-3">
                <img
                  src={PharmacyLogo}
                  className="initial-38-2"
                  alt="stewards pharmacy"
                />
              </div>
              <div className="text-center pt-2 mb-3">
                <h5 className="text-break initial-38-4">Kumasi, Ghana</h5>
                <h5 className="initial-38-4 initial-38-3">
                  Phone : +233244469539
                </h5>
              </div>
              <span className="initial-38-5">
                ---------------------------------------------------------------------------------
              </span>
              <div className="row mt-3">
                <div className="col-6">
                  <h5>
                    Payment ID :
                    <span className="font-light">
                      {" "}
                      {paymentDetails?.payment_id}
                    </span>
                  </h5>
                </div>
                <div className="col-6">
                  <h5>
                    <span className="font-light">
                      {" "}
                      {paymentDetails?.date} {paymentDetails?.time}{" "}
                    </span>
                  </h5>
                </div>
                {paymentDetails?.sale?.customer ? (
                  <div className="col-12">
                    <h5>
                      Customer Name :
                      <span className="font-light">
                        {paymentDetails?.sale?.customer?.customer_name}
                      </span>
                    </h5>
                    <h5>
                      Phone :
                      <span className="font-light">
                        {paymentDetails?.sale?.customer?.customer_phone_number}
                      </span>
                    </h5>
                  </div>
                ) : (
                  <div className="col-12">
                    <h5>
                      Customer Name :
                      <span className="font-light">Walk in customer</span>
                    </h5>
                  </div>
                )}
              </div>
              <h5 className="text-uppercase" />
              <span className="initial-38-5">
                ---------------------------------------------------------------------------------
              </span>
              <table className="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th className="initial-38-6 border-top-0 border-bottom-0">
                      QTY
                    </th>
                    <th className="initial-38-7 border-top-0 border-bottom-0">
                      DESC
                    </th>
                    <th className="initial-38-7 border-top-0 border-bottom-0">
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paymentDetails?.sale?.sale_items.map((saleItem, index) => (
                    <tr key={index}>
                      <td className="">2</td>
                      <td className="">
                        {saleItem.drug_name} <br />
                        {/* <div className="font-size-sm text-body">
                                      <span className="text-capitalize">Category : </span>
                                      <span className="font-weight-bold">Anti-depresant </span>
                                    </div> */}
                        <span>
                          Unit Price : GHS {saleItem?.drug_final_price}
                        </span>
                        <br />
                        <span>Qty : {saleItem?.quantity}</span>
                        <br />
                        {/* <span>Discount : GHS 80.00</span> */}
                      </td>
                      <td className="w-28p"> GHS {saleItem?.total_cost}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="px-3">
                <dl className="row text-right justify-content-center">
                  {/* <dt className="col-6">Current Amount Paid:</dt>
                  <dd className="col-6">GHS{paymentDetails?.amount_paid}</dd> */}
                  <dt className="col-6">Total Amount Paid :</dt>
                  <dd className="col-6">
                    GHS {paymentDetails?.sale?.amount_paid}
                  </dd>
                  <dt className="col-6">Outstanding balance:</dt>
                  <dd className="col-6">
                    GHS {paymentDetails?.sale?.outstanding_balance}
                  </dd>
                  {/* <dt className="col-6">Coupon Discount:</dt>
                          <dd className="col-6">- 0.00$</dd> */}
                  <br />
                  <dt className="col-6 font-20px">Total Cost:</dt>
                  <dd className="col-6 font-20px">
                    GHS {paymentDetails?.sale?.total_cost}
                  </dd>
                </dl>
                <span className="initial-38-5">
                  ---------------------------------------------------------------------------------
                </span>
                <h5 className="text-center pt-1">
                  <span className="d-block">"""THANK YOU"""</span>
                </h5>
                <span className="initial-38-5">
                  ---------------------------------------------------------------------------------
                </span>
                <span className="d-block text-center">
                  Copyright © {currentYear}, Stewards Pharmacy
                </span>
                <span className="d-block text-center">
                  Date Printed: {currentDate.toLocaleDateString(undefined, dateOptions)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
};

export default PaymentReceipt;
