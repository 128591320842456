import { createContext, useContext, useEffect, useState } from "react";
import { databaseUrl } from "../utils/data";
import axios from "axios";
import { toast } from "react-toastify";


export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    
    const [user, setUser] = useState(null)
    const [authToken, setAuthToken] = useState(null)
    const [categoriesList, setCategoriesList] = useState([])
    const [drugList, setDrugList] = useState([])
    const [pharmacySetup, setPharmacySetup] = useState(null)
    
    const authData = JSON.parse(localStorage.getItem('auth'))
    const getUser = async () => {
        if(!authData){
            // window.location.href = "/"
            console.log("Not Found")
        }else{
            
            setAuthToken(authData?.access)
            const config = {
                "headers":{
                    "Content-Type": "application/json",
                    'Authorization':`JWT ${authData?.access}`
                }
            }
            try {
                await axios.get(`${databaseUrl}/employee/me/`,config).then((response)=>{
                    
                    if(!response.data?.employee){
                        setUser(null)
                        toast.error("You don't have an employee account! Contact administrator to create one for you.")
                        setAuthToken(null)
                        localStorage.removeItem("auth")
                        window.location.href("/")
                    }else{
                        setUser(response.data);
                    }
                })
            } catch (error) {
                console.clear()
            }
        }
        
        // setUser(localStorage.getItem('user'));
    }

    const getCategoriesList = async () => {
        const config = {
            "headers":{
              "Content-Type": "multipart/form-data",
              'Authorization':`JWT ${authData?.access}`
            }
          }
        
        await axios.get(`${databaseUrl}/inventory/medicine-categories/`, config).then((response)=>{
            setCategoriesList(response.data);
            
        }).catch((error)=>{
            console.clear()
        })
    }

    const getMedicineList = async () => {
        const config = {
            "headers":{
              "Content-Type": "multipart/form-data",
              'Authorization':`JWT ${authData?.access}`
            }
          }
        
        await axios.get(`${databaseUrl}/inventory/medicine/`, config).then((response)=>{
            setDrugList(response.data);
        }).catch((error)=>{
            console.clear()
        })
    }

    const getPharmacySetup = async () => {
        const config = {
            "headers":{
              "Content-Type": "multipart/form-data",
              'Authorization':`JWT ${authData?.access}`
            }
          }
        await axios.get(`${databaseUrl}/account/pharmacy-setup/`, config).then((response)=>{
            
            setPharmacySetup(response.data)
        }).catch((error)=>{
            
        })
    }

    const contextData = {
        user,
        authToken,
        categoriesList,
        drugList,
        pharmacySetup
    }

    useEffect(() => {
        getUser();
        getCategoriesList();
        getMedicineList();
        getPharmacySetup();
    },[])
    if (!user){
        console.clear()
    }
    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthenticate = () => useContext(AuthContext)