import React, { useRef, useState } from 'react'
import ReactPaginate from "react-paginate";
import ReactToPrint from 'react-to-print';
import { toast } from 'react-toastify';
import { useAuthenticate } from '../../Context/AuthContext';
import axios from 'axios';
import { databaseUrl } from '../../utils/data';
import Loading from '../../Components/Loading';
import NoData from '../../Components/NoData';

const AttendanceReport = () => {
    const {authToken} = useAuthenticate()
    const attendanceListRef = useRef()
    const [attendanceList, setAttendanceList] = useState([])
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [gettingAttendanceList, setGettingAttendanceList] = useState(false)

    const itemsPerPage = 7;
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = attendanceList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(attendanceList.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % attendanceList.length;
        setItemOffset(newOffset);
      };


    const getStaffAttendance = async (e) =>{
      e.preventDefault();
      if(startDate === "" || endDate === ""){
        toast.error("Please select both start and end dates")
        return false
      }else{
        setGettingAttendanceList(true)
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${authToken}`,
          },
        };
        axios.get(`${databaseUrl}/attendance/staff-attendance/${startDate}/${endDate}/`, config).then((response)=>{
          setAttendanceList(response.data);
          setGettingAttendanceList(false)
        }).catch((error)=>{
          toast.error("Something went wrong.")
          setGettingAttendanceList(false)
        });
      }
    }

    if(gettingAttendanceList){
      return <Loading />
    }else{
      return (
        <main id="content" role="main" className="main main-page pointer-event">
          <div className="content container-fluid">
            <div className="page-header">
              <h1 className="mb-0 page-header-title">
                <span className="page-header-icon">
                  <img
                    src="https://grofresh-admin.6amtech.com/public/assets/admin/img/all_orders.png"
                    className="w--20"
                    alt=""
                  />
                </span>
                <span className="">Attendance Report</span>
              </h1>
            </div>
            <div className="card">
              <div className="card-header shadow flex-wrap p-20px border-0">
                <h5 className="form-bold w-100 mb-3">Select Date Range</h5>
                <form onSubmit={(e)=>getStaffAttendance(e)} className="w-100 mt-5">
                  <div className="row g-3 g-sm-4 g-md-3 g-lg-4">
                    <div className="col-sm-6 col-md-4 col-lg-4">
                      <div className="input-date-group">
                        <label className="input-label">
                          Start Date
                        </label>
                        <label className='w-100'>
                          <input
                            type="date"
                            id="start_date"
                            name="start_date"
                            className="form-control min-h-45px"
                            onChange={(e)=>setStartDate(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4">
                      <div className="input-date-group">
                        <label className="input-label" htmlFor="end_date">
                          End Date
                        </label>
                        <label className="w-100">
                          <input
                            type="date"
                            id="end_date"
                            name="end_date"
                            onChange={(e)=>setEndDate(e.target.value)}
                            className="js-flatpickr form-control flatpickr-custom min-h-45px"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-2">
                      
                      <button
                        type="submit"
                        id="show_filter_data"
                        className="btn w-100 btn--primary min-h-45px p-2"
                      >
                        Show data
                      </button>
                    </div>
                  </div>
                </form>
              </div>
    
              <div className="card-body p-20px">
                <div className="order-top">
                  <div className="card--header">
                    <ReactToPrint trigger={()=>(
                    <button className="btn btn-primary">
                      Print List
                    </button>
                    )} 
                    content = {()=>attendanceListRef.current}
                    />
                  </div>
                </div>
                <div className="table-responsive datatable-custom">
                  <table
                  ref={attendanceListRef}
                    className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                    style={{ width: "100%" }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th className="">SL</th>
                        <th>Date</th>
                        <th>Check In Time</th>
                        <th>Check Out Time</th>
                      </tr>
                    </thead>
                    <tbody id="set-rows">
                      {currentItems.map((attendance, index) => (
                        <tr key={index} className="status-delivered class-all">
                          <td className="">{index + 1}</td>
                          <td>{attendance.date}</td>
                          <td>
                            <div>
                              {attendance.check_in_time}
                            </div>
                          </td>
                          <td>
                            <div>
                              {attendance.check_out_time}  
                            </div>
                          </td>
                          
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {attendanceList.length <= 0 && (
                    <NoData />
                  )}
              </div>
              <div className="card-footer border-0">
                <div className="d-flex justify-content-center justify-content-sm-end">
                  <nav>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="›"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="‹"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      activeClassName="page-item active"
                      pageLinkClassName="page-link"
                      pageClassName="page-item"
                      previousClassName="page-item"
                      nextClassName="page-item"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                    />
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </main>
      )
    }

}

export default AttendanceReport