import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { databaseUrl } from "../../utils/data";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { MdDeleteOutline, MdOutlineRemoveRedEye } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";
import { RotatingLines } from "react-loader-spinner";
import { useAuthenticate } from "../../Context/AuthContext";
import { toast } from "react-toastify";
import Loading from "../../Components/Loading";

const CustomerSales = () => {
  const { customer_id } = useParams();
  const {authToken} = useAuthenticate();
  const [gettingSales, setGettingSales] = useState(false);
  const [customerSales, setCustomerSales] = useState([]);
  const [customerSaleHistory, setCustomerSaleHistory] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");


  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = customerSales.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(setCustomerSales.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % setCustomerSales.length;
    setItemOffset(newOffset);
  };

  const filterSaleList = (search) => {
    if (search === "") {
        setCustomerSales(customerSaleHistory);
    } else {
      const filteredSaleList = customerSales.filter((sale) => {
        return search.toLowerCase() === ""
          ? sale
          : sale.sale_id.toLowerCase().includes(search.toLowerCase());
      });
      setCustomerSales(filteredSaleList);
    }
  };

  const getCustomerSales = async () => {
    setGettingSales(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authToken}`,
      },
    };

    await axios
      .get(`${databaseUrl}/sales/customers/${customer_id}/sales`, config)
      .then((response) => {
        setCustomerSales(response.data);
        setCustomerSaleHistory(response.data);
        setGettingSales(false);
      })
      .catch((error) => {
        setGettingSales(false);
      });
  };

  const filterSaleHistory = async (e) => {
    e.preventDefault();
    if (filterStartDate === "" && filterEndDate === "") {
      toast.error("Please specify the start date and end date");
      return false;
    }
    setGettingSales(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authToken}`,
      },
    };

    await axios
      .get(
        `${databaseUrl}/sales/list/?date__gte=${filterStartDate}&date__lte=${filterEndDate}`,
        config
      )
      .then((response) => {
        setCustomerSaleHistory(response.data);
        setCustomerSales(response.data);
        setGettingSales(false);
      })
      .catch((error) => {
        console.clear()
        setGettingSales(false);
      });
  };

  const clearData = () => {
    setFilterStartDate("");
    setFilterEndDate("");
    getCustomerSales();
  };

  const deleteSale = (sale_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `JWT ${authToken}`,
          },
        };
        try {
          await axios
            .delete(`${databaseUrl}/sales/${sale_id}/details`, config)
            .then((response) => {
              Swal.fire({
                title: "Deleted!",
                text: "Sale has been deleted.",
                icon: "success",
              }).then(() => {
                setGettingSales();
              });
            });
        } catch (error) {
          toast.error("Something went wrong.");
        }
      }
    });
  };

  useEffect(() => {
    getCustomerSales();
  }, []);

  if(gettingSales) {
    return <Loading />
  }else{
      return (
        <main id="content" role="main" className="main main-page pointer-event">
          <div className="content container-fluid">
            <div className="page-header">
              <h1 className="mb-0 page-header-title">
                <span className="page-header-icon">
                  <img
                    src="https://grofresh-admin.6amtech.com/public/assets/admin/img/all_orders.png"
                    className="w--20"
                    alt=""
                  />
                </span>
                <span className="">All Sales</span>
              </h1>
            </div>
            <div className="card">
              <div className="card-header shadow flex-wrap p-20px border-0">
                <h5 className="form-bold w-100 mb-3">Select Date Range</h5>
                <form onSubmit={(e) => filterSaleHistory(e)} className="w-100 mt-5">
                  <div className="row g-3 g-sm-4 g-md-3 g-lg-4">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <div className="input-date-group">
                        <label className="input-label" htmlFor="start_date">
                          Start Date
                        </label>
                        <label className="w-100">
                          <input
                            type="date"
                            id="start_date"
                            name="start_date"
                            value={filterStartDate}
                            className="js-flatpickr form-control flatpickr-custom min-h-45px"
                            onChange={(e) => setFilterStartDate(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <div className="input-date-group">
                        <label className="input-label" htmlFor="end_date">
                          End Date
                        </label>
                        <label className="w-100">
                          <input
                            type="date"
                            id="end_date"
                            name="end_date"
                            value={filterEndDate}
                            className="js-flatpickr form-control flatpickr-custom min-h-45px"
                            onChange={(e) => setFilterEndDate(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-12 col-lg-4 __btn-row">
                      <a
                        id=""
                        className="btn w-100 btn--reset min-h-45px"
                        onClick={() => clearData()}
                      >
                        Clear
                      </a>
                      <button
                        type="submit"
                        id="show_filter_data"
                        className="btn w-100 btn--primary min-h-45px"
                      >
                        Show data
                      </button>
                    </div>
                  </div>
                </form>
              </div>
    
              <div className="card-body p-20px">
                <div className="order-top">
                  <div className="card--header">
                    <form>
                      <div className="input-group">
                        <input
                          id="datatableSearch_"
                          type="search"
                          name="search"
                          className="form-control"
                          placeholder="Ex : Search by invoice ID"
                          aria-label="Search"
                          onChange={(e) => filterSaleList(e.target.value)}
                        />
                      </div>
                    </form>
                    
                  </div>
                </div>
                <div className="table-responsive datatable-custom">
                  {gettingSales ? (
                    <div className="text-center" style={{ marginTop: "40px" }}>
                      <RotatingLines
                        visible={true}
                        height="96"
                        width="96"
                        color="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  ) : (
                    <table
                      className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                      style={{ width: "100%" }}
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="">SL</th>
                          <th className="table-column-pl-0">Invoice ID</th>
                          <th>Date</th>
                          <th>Customer</th>
                          <th className="text-right">Total amount</th>
                          <th className="text-right">Amount Paid</th>
                          <th className="text-right">Outstanding balance</th>
                          <th>
                            <div className="text-center">Payment Status</div>
                          </th>
                          <th>
                            <div className="text-center">Action</div>
                          </th>
                        </tr>
                      </thead>
    
                      <tbody id="set-rows">
                        {currentItems.map((sale, index) => (
                          <tr key={index} className="status-delivered class-all">
                            <td className="">{index + 1}</td>
                            <td className="table-column-pl-0">{sale.sale_id}</td>
                            <td>{sale.date}</td>
    
                            <td>
                              {!sale?.customer ? (
                                "Walk in customer"
                              ) : (
                                <>
                                  <div>
                                    <a
                                      className="text-body text-capitalize font-medium"
                                      href=""
                                    >
                                      {sale?.customer?.customer_name}
                                    </a>
                                  </div>
                                  <div className="text-sm">
                                    <Link
                                      to={`tel:${sale?.customer?.customer_phone_number}`}
                                    >
                                      {sale?.customer?.customer_phone_number}
                                    </Link>
                                  </div>
                                </>
                              )}
                            </td>
    
                            <td className="text-right">
                              
                                GHS {sale.total_cost}
                              
                            </td>
                            <td className="text-right">
                                GHS {sale.amount_paid}
                            </td>
                            <td className="text-right">
                                GHS {sale.outstanding_balance}
                            </td>
                            <td className="text-capitalize text-center">
                              {sale.payment_status === "Full Payment" ? (
                                <span className="badge badge-soft-success">
                                  {sale.payment_status}
                                </span>
                              ) : (
                                <span className="badge badge-soft-danger">
                                  {sale.payment_status}
                                </span>
                              )}
                            </td>
                            <td>
                              <div className="btn--container justify-content-center">
                                <Link
                                  className="action-btn btn--primary btn-outline-primary"
                                  to={`/sales/${sale?.id}/details`}
                                >
                                  <MdOutlineRemoveRedEye />
                                </Link>
                                <Link
                                  className="action-btn btn-outline-primary-2"
                                  target="_blank"
                                  to="#"
                                >
                                  <IoPrintSharp />
                                </Link>
                                <Link
                                  className="action-btn btn--danger btn-outline-danger"
                                  onClick={() => deleteSale(sale.id)}
                                >
                                  <MdDeleteOutline />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
              <div className="card-footer border-0">
                <div className="d-flex justify-content-center justify-content-sm-end">
                  <nav>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="›"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="‹"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      activeClassName="page-item active"
                      pageLinkClassName="page-link"
                      pageClassName="page-item"
                      previousClassName="page-item"
                      nextClassName="page-item"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                    />
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
  }

};

export default CustomerSales;
