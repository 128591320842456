import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { toast } from "react-toastify";
import { databaseUrl } from "../../utils/data";
import { RotatingLines } from "react-loader-spinner";
import { useAuthenticate } from "../../Context/AuthContext";
import PharmacyLogo from "../../assets/stewards1.png"

const Login = () => {
  const {user} = useAuthenticate()
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    username: "",
    password: "",
  });

  const handleFormChange = (e) => {
    setLoginDetails({
      ...loginDetails,
      [e.target.name]: e.target.value,
    });
  };

  const loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(loginDetails);
    try {
      await axios
        .post(`${databaseUrl}/api/token/`, body, config)
        .then((response) => {
          localStorage.setItem(
            "auth",JSON.stringify(response.data)
          );
          setLoading(false);
          window.location.href = "/dashboard"
        });
    } catch (error) {
      if (error.response?.data) {
        toast.error(error.response?.data?.detail);
      } else {
        toast.error("Something went wrong, please try again later");
      }
      setLoading(false)
    }
    // setLoading(true)
    // if(loginDetails.username === "admin" && loginDetails.password === "admin"){
    //   localStorage.setItem("user",JSON.stringify({
    //     "username":"admin",
    //     "role": "Administrator",
    //     "full_name":"Richard Mensah"
    //   }))
    // }else{
    //   toast.error("Invalid credentials")
    // }
  };

  // useEffect(()=>{
  //   if(user){
  //     window.location.href="/dashboard"
  //   }
  // },[user])

  return (
    <main id="content" role="main" className="main">
      <div className="auth-wrapper">
        <div className="auth-wrapper-left">
          <div className="auth-left-cont">
            {/* TODO: REPLACE WITH PHARMACY LOGO */}
            <img
              src={PharmacyLogo}
              alt="stewards pharmacy"
            />
            {/* <h1 style={{fontSize:"50px", fontWeight:"900"}} className="text-primary">
              STEWARDS PHARMACY
            </h1> */}
            <h2 className="title">
              Your <span className="d-block">One Stop Pharmacy</span>
              <span className="text-secondary">For All Your Needs....</span>
            </h2>
          </div>
        </div>

        <div className="auth-wrapper-right">
          <div className="auth-wrapper-form">
            <img src={PharmacyLogo} className="pharmacy-logo" alt="" />
            <form onSubmit={(e) => loginUser(e)}>
              <div className="auth-header">
                <div className="mb-5">
                  <h2 className="title">Sign In</h2>
                  <div>Welcome back</div>
                  <span className="badge badge-soft-info mt-2">
                    ( Admin or employee signin )
                  </span>
                </div>
              </div>
              <div className="js-form-message form-group">
                <label
                  className="input-label text-capitalize"
                  htmlFor="signinSrEmail"
                >
                  Your username
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="username"
                  id="username"
                  placeholder="Username"
                  aria-label="username"
                  onChange={(e) => handleFormChange(e)}
                  required
                />
              </div>
              <div className="js-form-message form-group">
                <label
                  className="input-label"
                  htmlFor="signupSrPassword"
                >
                  <span className="d-flex justify-content-between align-items-center">
                    Password
                  </span>
                </label>
                <div className="input-group input-group-merge">
                  <input
                    type={`${!passwordVisible ? "password" : "text"}`}
                    className="js-toggle-password form-control form-control-lg"
                    name="password"
                    id="signupSrPassword"
                    placeholder="Your password"
                    aria-label="Your password"
                    required
                    onChange={(e) => handleFormChange(e)}
                  />
                  <div id="changePassTarget" className="input-group-append">
                    <a
                      className="input-group-text"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {!passwordVisible ? <FaRegEyeSlash /> : <FaRegEye />}
                    </a>
                  </div>
                </div>
              </div>
              {!loading ? (
                <button type="submit" className="btn btn-block btn--primary">
                  Login
                </button>
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <RotatingLines
                    visible={true}
                    height="36"
                    width="36"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
