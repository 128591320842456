import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthenticate } from '../Context/AuthContext'

const ProtectedRoutes = ({component: Component, ...rest}) => {
    
    const {user} = useAuthenticate();
    
    
    if (!user || user === "undefined" || user === undefined){
        <Navigate to="/" />
    }else{
        return (
          <Component {...rest} />
        )
    }
}

export default ProtectedRoutes