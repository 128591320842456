import React, { useEffect, useState } from "react";
import { databaseUrl } from "../../utils/data";
import ReactPaginate from "react-paginate";
import AddNewCustomerModal from "./AddNewCustomerModal";
import { MdDeleteOutline } from "react-icons/md";
import SaleDrugDetailsModal from "./SaleDrugDetailsModal";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useAuthenticate } from "../../Context/AuthContext";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import LogoSmall from "../../assets/stewards_small.png";
import NoData from "../../Components/NoData";

const NewSale = () => {
  const { drugList, categoriesList, authToken } = useAuthenticate();
  const [customersList, setCustomersList] = useState([]);
  const [placingOrder, setPlacingOrder] = useState(false);
  const [gettingMedicineList, setGettingMedicineList] = useState(false);
  const [medicineList, setMedicineList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [addMedicineModal, setAddMedicineModal] = useState(false);
  const [subtotal, setSubtotal] = useState("0.00");
  const [totalTax, setTotalTax] = useState("0.00");
  const [totalCost, setTotalCost] = useState(0.0);
  const [cart, setCart] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState("Full Payment");
  const [customer, setCustomer] = useState("Walk in customer");
  const [amountPaid, setAmountPaid] = useState("0.00");
  const [balance, setBalance] = useState("0.00");

  

  const displayAddMedicineModal = (medicine) => {
    setSelectedMedicine(medicine);
    setAddMedicineModal(true);
  };

  const getMedicineList = async (page, searchQuery) => {
    setGettingMedicineList(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${authToken}`,
      },
    };

    await axios
      .get(
        `${databaseUrl}/inventory/medicine/?page=${page}&search=${searchQuery}`,
        config
      )
      .then((response) => {
        setMedicineList(response.data?.results);
        setNextPage(response.data?.next);
        setPrevPage(response.data?.previous);
        setTotalPages(Math.ceil(response.data?.count / 20));
       
        setGettingMedicineList(false);
      })
      .catch((error) => {
        console.clear();
        setGettingMedicineList(false);
      });
  };

  const filterDrugList = (searchValue) => {
    setSearch(searchValue);
    setCurrentPage(1);
  };
  const filterByCategory = (category_id) => {
    if (category_id === "All Categories") {
      setMedicineList(drugList);
    } else {
      const filteredMedicineList = drugList.filter((medicine) => {
        return category_id === ""
          ? medicine
          : parseInt(medicine.category) === parseInt(category_id);
      });
      setMedicineList(filteredMedicineList);
    }
  };

  const removeCartItem = (index) => {
    const cartData = [...cart];
    const cartItemTotalCost =
      cartData[index].drug_unit_price * cartData[index].quantity;
    const subTotal = parseFloat(
      parseFloat(subtotal) - parseFloat(cartItemTotalCost)
    ).toFixed(2);
    const initialUnitTaxAmount =
      parseFloat(cartData[index]?.drug_final_price) -
      parseFloat(cartData[index]?.drug_unit_price);
    const finalTaxAmount = parseFloat(
      cartData[index]?.quantity * initialUnitTaxAmount
    ).toFixed(2);
    const newTotalTax = parseFloat(
      parseFloat(totalTax) - finalTaxAmount
    ).toFixed(2);

    const cartItemFinalTotalCost =
      cartData[index].drug_final_price * cartData[index].quantity;
    const newTotalCost = parseFloat(
      parseFloat(totalCost) - parseFloat(cartItemFinalTotalCost)
    ).toFixed(2);

    cartData.splice(index, 1);
    setCart(cartData);
    setSubtotal(subTotal);
    setTotalTax(newTotalTax);
    setTotalCost(newTotalCost);
  };

  const emptyCart = () => {
    setCart([]);
    setSubtotal("0.00");
    setTotalCost("0.00");
    setTotalTax("0.00");
    setAmountPaid("0.00");
    setBalance("0.00");
  };

  const placeOrder = async (e) => {
    e.preventDefault();
    if (paymentStatus === "Part Payment" && customer === "Walk in customer") {
      toast.error("You cannot accept part payment for a walk in customer");
      return false;
    }

    if (cart.length <= 0) {
      toast.error("Cart must not be empty");
    } else {
      setPlacingOrder(true);
      let amount_paid = 0.0;
      let outstanding_balance = 0.0;
      if (paymentStatus === "Full Payment") {
        amount_paid = totalCost;
        outstanding_balance = 0.0;
      } else {
        amount_paid = amountPaid;
        // outstanding_balance = balance;
        outstanding_balance = parseFloat(
          parseFloat(totalCost) - parseFloat(amountPaid)
        ).toFixed(2);
      }
      const body = JSON.stringify({
        cart: cart,
        payment_status: paymentStatus,
        customer: customer,
        amount_paid: amount_paid,
        total_cost: totalCost,
        outstanding_balance: outstanding_balance,
        // sub_total: subtotal,
        // total_tax: totalTax
      });

      setPlacingOrder(false);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${authToken}`,
        },
      };

      try {
        await axios
          .post(`${databaseUrl}/sales/`, body, config)
          .then((response) => {
            setPlacingOrder(false);
            if (response.status === 201) {
              Swal.fire({
                title: "Recorded!",
                text: "Sale has been recorded.",
                icon: "success",
              }).then(() => {
                emptyCart();
                window.location.href = `/sales/${response.data?.id}/details`;
              });
            }
          });
      } catch (error) {
        toast.error("Something went wrong.");
        setPlacingOrder(false);
      }
    }
  };

  const getCustomersList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authToken}`,
      },
    };
    try {
      await axios
        .get(`${databaseUrl}/sales/customers/`, config)
        .then((response) => {
          if (response.status === 200) {
            setCustomersList(response.data);
          }
        });
    } catch (error) {
      
      toast.error("Something went wrong");
    }
  };

  const handleNextPage = () => {
    if (nextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (prevPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalNumbersToShow = 3;
    const totalPagesToShow = 5;

    let startPage = Math.max(1, currentPage - totalNumbersToShow);
    let endPage = Math.min(totalPages, currentPage + totalNumbersToShow);

    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push("...");
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((number) => (
      <li
        key={number}
        onClick={() => (number !== "...") & handlePageClick(number)}
        className={number === currentPage ? "page-item active" : "page-item"}
      >
        <a className="page-link">{number}</a>
      </li>
    ));
  };

  useEffect(() => {
    getMedicineList(currentPage, search);
  }, [currentPage, search]);

  useEffect(() => {
    getCustomersList();
  }, []);

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="d-flex flex-wrap">
          <div className="order--pos-left">
            <div className="card">
              <div className="card-header m-1 bg-light border-0">
                <h5 className="card-title">
                  <span> Medicine section </span>
                </h5>
              </div>
              <div className="card-body">
                <div className="row mb-4 g-3">
                  {/* FILTER BY CATEGORY */}
                  <div className="col-sm-6">
                    <div className="input-group header-item">
                      <select
                        name="category"
                        id="category"
                        className="form-control js-select2-custom mx-1"
                        title="Select category"
                        onChange={(e) => filterDrugList(e.target.value)}
                      >
                        <option value="">All Categories</option>
                        {categoriesList.map((category, index) => (
                          <option key={index} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* SEARCH BOX */}
                  <div className="col-sm-6">
                    <form id="search-form">
                      <div className="input-group input-group-merge input-group-flush">
                        <div className="input-group-prepend w--30 justify-content-center">
                          <div className="input-group-text">
                            <i className="tio-search" />
                          </div>
                        </div>
                        <input
                          id="datatableSearch"
                          type="search"
                          defaultValue=""
                          name="search"
                          className="form-control rounded border"
                          placeholder="Search by medicine name or generic name"
                          aria-label="Search here"
                          onChange={(e) => filterDrugList(e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                </div>

                {/* DRUG LIST */}
                <div id="items">
                  {
                    gettingMedicineList ? (
                      <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <RotatingLines
                    visible={true}
                    height="46"
                    width="46"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                  </div>
                    ):(
                        <div className="row g-1">
                          {medicineList.map((medicine, index) => (
                            <div key={index} className="order--item-box item-box">
                              <div
                                className="product-card card"
                                onClick={() => displayAddMedicineModal(medicine)}
                              >
                                <div className="card-header inline_product clickable p-0">
                                  
                                </div>
                                <div className="card-body inline_product text-center p-1 clickable">
                                  <div className="product-title1 text-dark font-weight-bold">
                                    {medicine.name}
                                  </div>
                                  <div className="justify-content-between text-center">
                                    <div className="product-price text-center">
                                      GHS {medicine.final_price}
                                    </div>
                                    <b>Quantity Left: </b>{medicine.quantity}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          {/* <div className="order--item-box item-box">
                            <div
                              className="product-card card"
                              onclick="quickView('37')"
                            >
                              <div className="card-header inline_product clickable p-0">
                                <img
                                  src="https://grofresh-admin.6amtech.com/storage/app/public/product/2023-10-21-653350d7c62ba.png"
                                  onerror="this.src='https://grofresh-admin.6amtech.com/public/assets/admin/img/160x160/2.png'"
                                  className="w-100 h-100 object-cover aspect-ratio-80"
                                />
                              </div>
                              <div className="card-body inline_product text-center p-1 clickable">
                                <div className="product-title1 text-dark font-weight-bold">
                                  Organic Almo...
                                </div>
                                <div className="justify-content-between text-center">
                                  <div className="product-price text-center">
                                    190.00$
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                    )
                  }
                  {medicineList.length <= 0 && !gettingMedicineList &&  <NoData />}
                </div>
                <div className="pt-4">
                  <nav
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    
                    <ul className="pagination">
                      <li
                        className={
                          !prevPage ? "page-item disabled" : "page-item"
                        }
                        aria-label="« Previous"
                      >
                        <a
                          onClick={handlePreviousPage}
                          className="page-link"
                          aria-hidden="true"
                        >
                          ‹
                        </a>
                      </li>
                      {renderPageNumbers()}

                      <li
                        className={
                          !nextPage ? "page-item disabled" : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          onClick={handleNextPage}
                          rel="next"
                          aria-label="Next »"
                        >
                          ›
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="order--pos-right">
            {/* BILLING SECTION */}
            <div className="card">
              <div className="card-header bg-light border-0 m-1">
                <h5 className="card-title">
                  <span> Billing section </span>
                </h5>
              </div>
              <div className="card-body p-0">
                <div className="px-4">
                  <div className="w-100">
                    <div className="d-flex flex-wrap flex-row py-2 add--customer-btn">
                      <select
                        id="customer"
                        name="customer"
                        onChange={(e) => setCustomer(e.target.value)}
                        className="js-data-example-ajax form-control m-1"
                      >
                        <option selected value="Walk in customer">
                          Walk in customer
                        </option>
                        {customersList.map((customer, index) => (
                          <option value={customer.id} key={index}>
                            {customer.customer_name}
                          </option>
                        ))}
                      </select>
                      <button
                        className="btn btn--primary rounded font-regular"
                        data-toggle="modal"
                        data-target="#add-customer"
                        type="button"
                        onClick={() => setAddCustomerModal(true)}
                      >
                        Add New Customer
                      </button>
                    </div>
                  </div>

                  <div className="w-100" id="cart">
                    <div className="d-flex flex-row cart--table-scroll">
                      <div className="table-responsive">
                        <table className="table table-bordered border-left-0 border-right-0 middle-align">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Item</th>
                              <th scope="col" className="text-center">
                                Qty
                              </th>
                              <th scope="col">Price</th>
                              <th scope="col">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cart.map((cartItem, index) => (
                              <tr key={index}>
                                <td>
                                  <div class="media align-items-center">
                                    <img
                                      class="avatar avatar-sm mr-1"
                                      src={`${
                                        cartItem?.drug_image
                                          ? "https://api.stewardspharmacy.com/" +
                                            cartItem?.drug_image
                                          : LogoSmall
                                      }`}
                                      alt={cartItem?.drug_name}
                                    />
                                    <div class="media-body">
                                      <h6 class="text-hover-primary mb-0">
                                        {cartItem?.drug_name}
                                      </h6>
                                      <small></small>
                                    </div>
                                  </div>
                                </td>
                                <td class="align-items-center text-center">
                                  <input
                                    type="number"
                                    data-key="0"
                                    id="51"
                                    class="amount--input form-control text-center"
                                    value={cartItem?.quantity}
                                    disabled
                                  />
                                </td>
                                <td class="text-center px-0 py-1">
                                  <div class="btn text-left">
                                    GHS {cartItem?.total_cost}
                                  </div>
                                </td>
                                <td>
                                  <div class="d-flex flex-wrap justify-content-center">
                                    <a
                                      onClick={() => removeCartItem(index)}
                                      class=""
                                    >
                                      <MdDeleteOutline />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="box p-3">
                      <dl className="row">
                        {/* <dt className="col-sm-6">Sub total :</dt>
                        <dd className="col-sm-6 text-right">GHS {subtotal}</dd> */}
                        {/* <dt className="col-sm-6">Product Discount:</dt>
                        <dd className="col-sm-6 text-right">- GHS 0.00</dd>
                        <dt className="col-sm-6">Extra Discount:</dt>
                        <dd className="col-sm-6 text-right">
                          <button
                            className="btn btn-sm"
                            type="button"
                            data-toggle="modal"
                            data-target="#add-discount"
                          >
                            <i className="tio-edit" />
                          </button>
                          - 0.00$
                        </dd> */}
                        {/* <dt className="col-sm-6">Tax :</dt>
                        <dd className="col-sm-6 text-right">GHS {totalTax}</dd> */}
                        <dt className="col-12">
                          <hr className="mt-0" />
                        </dt>
                        <dt className="col-sm-6">Total :</dt>
                        <dd className="col-sm-6 text-right h4 b">
                          GHS {totalCost}
                        </dd>
                        <dt className="col-12">
                          <hr className="mt-0" />
                        </dt>
                        {paymentStatus === "Part Payment" && (
                          <>
                            <dt className="col-sm-8">Amount Paid :</dt>

                            <dd className="col-sm-4 text-right">
                              <input
                                type="number"
                                class="form-control text-right"
                                value={amountPaid}
                                onChange={(e) => {
                                  setAmountPaid(e.target.value);
                                  setBalance(
                                    parseFloat(
                                      totalCost - e.target.value
                                    ).toFixed(2)
                                  );
                                }}
                                name="amount_paid"
                                id=""
                              />
                            </dd>

                            <dt className="col-sm-8">Outstanding Balance:</dt>
                            <dd className="col-sm-4 text-right h4 b">
                              GHS {amountPaid === "0.00" ? totalCost : balance}
                            </dd>
                          </>
                        )}
                      </dl>
                      <div>
                        <form onSubmit={(e) => placeOrder(e)} id="order_place">
                          <div className="pos--payment-options mt-3 mb-3">
                            <h5 className="mb-3">Payment Status</h5>
                            <ul>
                              <li style={{ display: "block" }}>
                                <label>
                                  <input
                                    type="radio"
                                    name="payment_method"
                                    defaultValue="Full Payment"
                                    hidden
                                    defaultChecked
                                    onChange={(e) =>
                                      setPaymentStatus(e.target.value)
                                    }
                                  />
                                  <span>Full Payment</span>
                                </label>
                              </li>
                              <li style={{ display: "block" }}>
                                <label>
                                  <input
                                    type="radio"
                                    name="payment_method"
                                    value="Part Payment"
                                    hidden
                                    onChange={(e) =>
                                      setPaymentStatus(e.target.value)
                                    }
                                  />
                                  <span>Part Payment</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                          <div className="row button--bottom-fixed g-1 bg-white">
                            {!placingOrder ? (
                              <>
                                <div className="col-sm-6">
                                  <a
                                    href="#"
                                    className="btn btn-outline-danger btn--danger btn-sm btn-block"
                                    onClick={() => emptyCart()}
                                  >
                                    <i className="fa fa-times-circle" /> Cancel
                                    Order
                                  </a>
                                </div>
                                <div className="col-sm-6">
                                  <button
                                    type="submit"
                                    className="btn btn--primary btn-sm btn-block"
                                  >
                                    <i className="fa fa-shopping-bag" />
                                    Place Order
                                  </button>
                                </div>
                              </>
                            ) : (
                              <div className="d-flex align-items-center justify-content-center col-sm-12">
                                <RotatingLines
                                  visible={true}
                                  height="96"
                                  width="96"
                                  color="grey"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  ariaLabel="rotating-lines-loading"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                />
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* UPDATE DISCOUNT MODAL */}
                    {/* <div className="modal fade" id="add-discount" tabIndex={-1}>
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Update discount</h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <form
                              action="https://grofresh-admin.6amtech.com/admin/pos/discount"
                              method="post"
                              className="row"
                            >
                              <input
                                type="hidden"
                                name="_token"
                                defaultValue="jcakta8LhAXeynbDhEoKKOuFtJuXrTHu3JFlkc1P"
                              />
                              <div className="form-group col-sm-6">
                                <label htmlFor="">Discount</label>
                                <input
                                  type="number"
                                  min={0}
                                  max=""
                                  defaultValue={0}
                                  id="extra_discount_input"
                                  className="form-control"
                                  name="discount"
                                  step="any"
                                  placeholder="Ex: 45"
                                />
                              </div>
                              <div className="form-group col-sm-6">
                                <label htmlFor="">Type</label>
                                <select
                                  name="type"
                                  className="form-control"
                                  id="discount_type_select"
                                >
                                  <option value="amount" selected="">
                                    Amount ($)
                                  </option>
                                  <option value="percent">Percent(%)</option>
                                </select>
                              </div>
                              <div className="col-sm-12">
                                <div className="btn--container justify-content-end">
                                  <button
                                    className="btn btn-sm btn--reset"
                                    type="reset"
                                  >
                                    Reset
                                  </button>
                                  <button
                                    className="btn btn-sm btn--primary"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* UPDATE TAX MODAL */}
                    {/* <div className="modal fade" id="add-tax" tabIndex={-1}>
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Update tax</h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <form
                              action="https://grofresh-admin.6amtech.com/admin/pos/tax"
                              method="POST"
                              className="row"
                            >
                              <input
                                type="hidden"
                                name="_token"
                                defaultValue="jcakta8LhAXeynbDhEoKKOuFtJuXrTHu3JFlkc1P"
                              />
                              <div className="form-group col-12">
                                <label htmlFor="">Tax (%)</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="tax"
                                  min={0}
                                />
                              </div>
                              <div className="col-sm-12">
                                <div className="btn--container">
                                  <button
                                    className="btn btn-sm btn--reset"
                                    type="reset"
                                  >
                                    Reset
                                  </button>
                                  <button
                                    className="btn btn-sm btn--primary"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* COUPON DISCOUNT MODAL */}
                    {/* <div
                      className="modal fade"
                      id="coupon-discount"
                      tabIndex={-1}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Coupon discount</h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body pb-2 pt-3">
                            <form className="row">
                              <input
                                type="hidden"
                                name="_token"
                                defaultValue="jcakta8LhAXeynbDhEoKKOuFtJuXrTHu3JFlkc1P"
                              />
                              <div className="form-group col-sm-12">
                                <input type="text" className="form-control" />
                              </div>
                              <div className="col-sm-12">
                                <div className="btn--container justify-content-end">
                                  <button
                                    className="btn btn-sm btn--reset"
                                    type="reset"
                                  >
                                    Reset
                                  </button>
                                  <button
                                    className="btn btn-sm btn--primary"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* PRODUCT DETAILS MODAL */}
      {addMedicineModal && (
        <SaleDrugDetailsModal
          drug={selectedMedicine}
          cart={cart}
          setCart={setCart}
          setAddMedicineModal={setAddMedicineModal}
          setSubtotal={setSubtotal}
          subtotal={subtotal}
          totalTax={totalTax}
          setTotalTax={setTotalTax}
          totalCost={totalCost}
          setTotalCost={setTotalCost}
        />
      )}

      {/* NEW CUSTOMER MODAL */}
      {addCustomerModal && (
        <AddNewCustomerModal
          setAddCustomerModal={setAddCustomerModal}
          getCustomersList={getCustomersList}
        />
      )}

      {/* <div className="modal fade" id="AddressModal" tabIndex={-1}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header bg-light border-bottom py-3">
              <h5 className="modal-title flex-grow-1 text-center">
                Delivery Information
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form id="delivery_address_store">
                
                <div className="row g-2" id="delivery_address">
                  <div className="col-md-6">
                    <label className="input-label" htmlFor="">
                      Contact person name
                      <span className="input-label-secondary text-danger">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="contact_person_name"
                      defaultValue=""
                      placeholder="Ex : Jhon"
                      required=""
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="input-label" htmlFor="">
                      Contact Number
                      <span className="input-label-secondary text-danger">
                        *
                      </span>
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      name="contact_person_number"
                      defaultValue=""
                      placeholder="Ex : +3264124565"
                      required=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="input-label" htmlFor="">
                      Road
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="road"
                      defaultValue=""
                      placeholder="Ex : 4th"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="input-label" htmlFor="">
                      House
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="house"
                      defaultValue=""
                      placeholder="Ex : 45/C"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="input-label" htmlFor="">
                      Floor
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="floor"
                      defaultValue=""
                      placeholder="Ex : 1A"
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="input-label" htmlFor="">
                      Longitude
                      <span className="input-label-secondary text-danger">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="longitude"
                      name="longitude"
                      defaultValue=""
                      readOnly=""
                      required=""
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="input-label" htmlFor="">
                      Latitude
                      <span className="input-label-secondary text-danger">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="latitude"
                      name="latitude"
                      defaultValue=""
                      readOnly=""
                      required=""
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="input-label">Address</label>
                    <textarea
                      name="address"
                      id="address"
                      className="form-control"
                      required=""
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-12">
                    <div className="d-flex justify-content-between">
                      <span className="text-primary">
                        * pin the address in the map to calculate delivery fee
                      </span>
                    </div>
                    <div id="location_map_div">
                      <input
                        id="pac-input"
                        className="controls rounded initial-8"
                        title="Search your location here"
                        type="text"
                        placeholder="Search here"
                      />
                      <div
                        id="location_map_canvas"
                        className="overflow-hidden rounded"
                        style={{ height: 200 }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <div className="btn--container justify-content-end">
                    <button
                      className="btn btn-sm btn--primary w-100"
                      type="button"
                      onclick="deliveryAdressStore()"
                      data-dismiss="modal"
                    >
                      Update Delivery address
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </main>
  );
};

export default NewSale;
