import React from "react";
import PharmacyLogo from "../assets/stewards1.png";
import { RotatingLines } from "react-loader-spinner";

const Loading = () => {
  return (
    
      <div className="row">
        <div className="col-md-12">
          <div id="loading" style={{
                // position: "fixed",
                // zIndex: 9999,
                // left: "40%",
                // top: "40%",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                width:"100vw",
                height:"100vh",
              }}>
            <div
              style={{display:"flex", flexDirection:"column", alignItems: "center",justifyContent: "center", gap:"10px"}}
            >
              <img width={400} src={PharmacyLogo} />
              <div
                style={{marginLeft:"100px"}}
              >
                <RotatingLines
                  visible={true}
                  height="46"
                  width="46"
                  color="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default Loading;
