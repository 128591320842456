import React, { useState } from "react";
import { MdEdit } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import { IoIosSearch } from "react-icons/io";
import { databaseUrl } from "../../utils/data";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import { useAuthenticate } from "../../Context/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import NoData from "../../Components/NoData";
import LogoSmall from "../../assets/stewards_small.png"

const Categories = () => {
  const {authToken, categoriesList} = useAuthenticate();
  
  const [data, setData] = useState({});
  const [addingCategory, setAddingCategory] = useState(false);

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = categoriesList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(categoriesList.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % categoriesList.length;
    setItemOffset(newOffset);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setAddingCategory(true);
    const config = {
      "headers":{
        "Content-Type": "multipart/form-data",
        "Authorization": `JWT ${authToken}`
      }
    }

    const formData = new FormData();
    formData.append("name", data?.name)
    if(data?.image){
      formData.append("image", data?.image)
    }

    try {
      await axios.post(`${databaseUrl}/inventory/medicine-categories/`, formData, config).then((response)=>{
        if(response.status === 201) {
          Swal.fire({
            title: "Created!",
            text: "Category has been created.",
            icon: "success",
          }).then(() => {
            setAddingCategory(false);
            window.location.reload();
          });      
        }
      })
    } catch (error) {
      toast.error("Something went wrong.")
      setAddingCategory(false);
    }

  };

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.files[0],
    });
  };

  const deleteCategory = (category_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async(result) => {
      if (result.isConfirmed) {
        const config = {
          "headers":{
            "Content-Type": "application/json",
            "Authorization": `JWT ${authToken}`
          }
        }
        await axios.delete(`${databaseUrl}/inventory/medicine-categories/${category_id}/`,config).then(()=>{
          Swal.fire({
            title: "Deleted!",
            text: "Category has been deleted.",
            icon: "success",
          }).then(()=>{
            window.location.reload();
          });
        })
      }
    });
  };

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/category.png"
                className="w--24"
                alt=""
              />
            </span>
            <span> Category setup </span>
          </h1>
        </div>
        <div className="row g-2">
          <div className="col-sm-12 col-lg-12">
            <div className="card">
              <div className="card-body pt-sm-0 pb-sm-4">
                <form onSubmit={(e) => submitForm(e)}>
                  <ul className="nav nav-tabs d-inline-flex mb--n-30">
                    <li className="nav-item">
                      <a
                        className="nav-link lang_link active"
                        href="#"
                        id="en-link"
                      >
                        Category Details
                      </a>
                    </li>
                  </ul>
                  <div className="row align-items-end g-4 mt-7">
                    <div className="col-sm-6 lang_form" id="en-form">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Category Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Ex: New Category"
                        maxLength={255}
                        required
                        onChange={(e) => handleFormChange(e)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label text-capitalize">
                        Category image
                      </label>
                      <div>
                        <input
                          type="file"
                          name="image"
                          id="customFileEg1"
                          accept=".jpg, .png, .jpeg"
                          onChange={(e) => handleFileChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="btn--container justify-content-end">
                        {!addingCategory ? (
                          <>
                            <button type="reset" className="btn btn--reset">
                              Reset
                            </button>
                            <button type="submit" className="btn btn--primary">
                              Submit
                            </button>
                          </>
                        ) : (
                          <RotatingLines
                            visible={true}
                            height="96"
                            width="96"
                            color="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-12">
            <div className="card">
              <div className="card-header border-0">
                <div className="card--header">
                  <h5 className="card-title">Category Table</h5>
                  {/* <form>
                    <div className="input-group">
                      <input
                        id="datatableSearch_"
                        type="search"
                        name="search"
                        maxLength={255}
                        className="form-control pl-5"
                        placeholder="Search by Name"
                        aria-label="Search"
                        defaultValue=""
                        required=""
                      />
                      <IoIosSearch className="tio-search tio-input-search" />

                      <div className="input-group-append">
                        <button type="submit" className="input-group-text">
                          Search
                        </button>
                      </div>
                    </div>
                  </form> */}
                </div>
              </div>
              <div className="table-responsive datatable-custom">
                <table className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">SL</th>
                      <th>Category image</th>
                      <th>Name</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((category, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td>
                          <img
                            src={category?.image ? "https://api.stewardspharmacy.com/" +  category.image : LogoSmall}
                            className="img--50 ml-3"
                            alt=""
                          />
                        </td>
                        <td>
                          <span className="d-block font-size-sm text-body text-trim-50">
                            {category.name}
                          </span>
                        </td>

                        <td>
                          <div className="btn--container justify-content-center">
                            <Link
                              className="action-btn"
                              to={`/medicine/categories/${category.id}/`}
                            >
                              <MdEdit />
                            </Link>
                            <Link
                              className="action-btn btn--danger btn-outline-danger"
                              to="#"
                            >
                              <AiOutlineDelete
                                onClick={() => deleteCategory(category.id)}
                              />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                
                {categoriesList.length <= 0 && (
                        <NoData />
                      )}
              </div>
            </div>
            <div className="card-footer border-0">
              <div className="d-flex justify-content-center justify-content-sm-end">
                <nav>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="›"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="‹"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    activeClassName="page-item active"
                    pageLinkClassName="page-link"
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Categories;
