import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { databaseUrl } from "../../utils/data";
import ReactPaginate from "react-paginate";
import { useAuthenticate } from "../../Context/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";

const RevenueReport = () => {
  const {authToken} = useAuthenticate()
  const revenueListRef = useRef()
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [revenueList, setRevenueList] = useState([])
  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = revenueList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(revenueList.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % revenueList.length;
    setItemOffset(newOffset);
  };

  

  const getRevenueReport = async () => {
    const config = {
      headers:{
        "Content-Type": "application/json",
        "Authorization": `JWT ${authToken}`
      }
    }
    await axios.get(`${databaseUrl}/report/revenue`, config).then((response)=>{
      setRevenueList(response.data);
    }).catch((error)=>{
      
      toast.error("Something went wrong")
    })
  }

  const filterRevenueReport = async (e) => {
    e.preventDefault();
    if(startDate === "" && endDate === ""){
      toast.error("Please select a start date and end date");
      return false
    }
    const config = {
      headers:{
        "Content-Type": "application/json",
        "Authorization": `JWT ${authToken}`
      }
    }
    await axios.get(`${databaseUrl}/report/revenue/${startDate}/${endDate}/`, config).then((response)=>{
      setRevenueList(response.data);
    }).catch((error)=>{
      toast.error("Something went wrong")
    })
  }

  useEffect(()=>{
    getRevenueReport()
  },[])

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="media align-items-center mb-2">
            <div className="">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/image-4.png"
                className="w--20"
                alt=""
              />
            </div>
            <div className="media-body pl-3">
              <div className="row">
                <div className="col-lg mb-3 mb-lg-0 text-capitalize">
                  <h1 className="page-header-title">Revenue Report Overview</h1>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            {/* FILTERING GROUNDS */}
            <form onSubmit={(e)=>filterRevenueReport(e)}>
              <div className="row g-3">
                <div className="col-12">
                  <div>
                    <label className="form-label mb-0 font-semibold">
                      Show Data By Date Range
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <label className="input-label">Start Date</label>
                  <label className="w-100">
                    <input
                      type="date"
                      name="from"
                      id="from_date"
                      className="js-flatpickr form-control flatpickr-custom flatpickr-input"
                      required
                      onChange={(e)=>setStartDate(e.target.value)}
                    />
                  </label>
                </div>
                <div className="col-md-4 col-sm-6">
                  <label className="input-label">End Date</label>
                  <label className="w-100">
                    <input
                      type="date"
                      name="to"
                      id="to_date"
                      className="js-flatpickr form-control flatpickr-custom flatpickr-input"
                      required
                      onChange={(e)=>setEndDate(e.target.value)}
                    />
                  </label>
                </div>
                <div className="col-md-4">
                  <label className="input-label d-none d-md-block">
                    &nbsp;
                  </label>
                  <div>
                    <button
                      type="submit"
                      className="btn btn--primary min-h-45px btn-block"

                    >
                      Show
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="row g-3 mt-3">

              {/* TABLE */}
              <div className="table-responsive datatable-custom">
                <ReactToPrint trigger={()=>(
                <button className="btn btn-primary mb-5">
                  Print List
                </button>

                )} content={()=>revenueListRef.current} />
                <table
                  className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                  style={{ width: "100%" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th className="">SL</th>
                      <th>Date</th>
                      <th className="text-center">Amount</th>
                      <th>
                        <div className="text-center">Action</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody id="set-rows">
                    {currentItems.map((revenue, index) => (
                      <tr key={index} className="status-delivered class-all">
                        <td className="">{index + 1}</td>
                        <td>{revenue.date}</td>
                        <td>
                          <div className="text-center">
                            {revenue.amount}
                          </div>
                        </td>

                        <td>
                          <div className="btn--container justify-content-center">
                            <Link
                              className="action-btn btn--primary btn-outline-primary"
                              to={`/reports/revenue/${revenue.raw_date}`}
                            >
                              <MdOutlineRemoveRedEye />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* LIST TO PRINT */}
              <div style={{display:"none"}}>
              <table
                  className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                  style={{ width: "100%", marginTop:"50px" }}
                  ref={revenueListRef}
                >
                  <thead className="thead-light">
                    <tr>
                      <th className="">SL</th>
                      <th>Date</th>
                      <th className="text-center">Amount</th>
                      
                    </tr>
                  </thead>
                  <tbody id="set-rows">
                    {revenueList.map((revenue, index) => (
                      <tr key={index} className="status-delivered class-all">
                        <td className="">{index + 1}</td>
                        <td>{revenue.date}</td>
                        <td>
                          <div className="text-center">
                            {revenue.amount}
                          </div>
                        </td>

                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center justify-content-sm-end">
                <nav>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="›"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="‹"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    activeClassName="page-item active"
                    pageLinkClassName="page-link"
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </main>
  );
};

export default RevenueReport;
