import React, { useEffect, useState } from "react";
import { MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useAuthenticate } from "../../Context/AuthContext";
import axios from "axios";
import { databaseUrl } from "../../utils/data";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import NoData from "../../Components/NoData";

const CustomerList = () => {
  const { authToken } = useAuthenticate();
  const [customersList, setCustomersList] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(true);

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = customers.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(customers.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % customers.length;
    setItemOffset(newOffset);
  };

  const filterCustomerList = (search) => {
    if (search === "") {
      setCustomers(customersList);
    } else {
      const filteredCustomersList = customers.filter((customer) => {
        return search.toLowerCase() === ""
          ? customer
          : customer.customer_name.toLowerCase().includes(search.toLowerCase());
      });
      setCustomers(filteredCustomersList);
      
    }
  };

  const getCustomersList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authToken}`,
      },
    };

    try {
      await axios
        .get(`${databaseUrl}/sales/customers/`, config)
        .then((response) => {
          setCustomersList(response.data);
          setCustomers(response.data);
          setLoadingCustomers(false);
        });
    } catch (error) {
      setLoadingCustomers(false);
      toast.error("An error occured please try again");
    }
  };

  const deleteCustomer = (customer_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${authToken}`,
        },
      };

      if (result.isConfirmed) {
        try {
          await axios
            .delete(`${databaseUrl}/sales/customer/${customer_id}/`, config)
            .then((response) => {
              Swal.fire({
                title: "Deleted!",
                text: "Customer has been deleted.",
                icon: "success",
              }).then(() => {
                getCustomersList();
              });
            });
        } catch (error) {
          toast.error("Could not delete Customer, please try again.");
        }
      }
    });
  };

  useEffect(() => {
    getCustomersList();
  }, []);

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/employee.png"
                className="w--20"
                alt=""
              />
            </span>
            <span>
              Customers list{" "}
              {/* <span className="badge badge-soft-primary ml-2 badge-pill">
                19
              </span> */}
            </span>
          </h1>
        </div>
        <div className="card">
          <div className="card-header">
            <div className="card--header">
              <form>
                <div className="input-group">
                  <input
                    id="datatableSearch_"
                    type="search"
                    name="search"
                    className="form-control"
                    placeholder="Search by Name "
                    aria-label="Search"
                    defaultValue=""
                    required=""
                    onChange={(e) => filterCustomerList(e.target.value)}
                  />
                </div>
              </form>
              {/* <div className="hs-unfold ml-auto">
                <a
                  className="js-hs-unfold-invoker btn btn-sm btn-outline-primary-2 dropdown-toggle min-height-40"
                  href="javascript:;"
                  data-hs-unfold-options='{
                              "target": "#usersExportDropdown",
                              "type": "css-animation"
                          }'
                >
                  <i className="tio-download-to mr-1" /> Export
                </a>
                <div
                  id="usersExportDropdown"
                  className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-sm-right"
                >
                  <span className="dropdown-header">Download Options</span>
                  <a
                    id="export-excel"
                    className="dropdown-item"
                    href="https://grofresh-admin.6amtech.com/admin/customer/export"
                  >
                    <img
                      className="avatar avatar-xss avatar-4by3 mr-2"
                      src="https://grofresh-admin.6amtech.com/public/assets/admin/svg/components/excel.svg"
                      alt="no data"
                    />
                    Excel
                  </a>
                </div>
              </div> */}
            </div>
          </div>
          <div className="table-responsive datatable-custom">
            {loadingCustomers ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "2rem",
                }}
              >
                <RotatingLines
                  visible={true}
                  height="96"
                  width="96"
                  color="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <table className="table table-borderless table-hover table-align-middle m-0 text-14px">
                <thead className="thead-light">
                  <tr className="word-nobreak">
                    <th className="">SL</th>
                    <th className="table-column-pl-0">Customer name</th>
                    <th>Contact info</th>
                    <th className="text-center">Total Orders</th>
                    <th className="text-center">Total Order Amount</th>
                    <th className="text-center">Total Amount Paid</th>
                    <th className="text-center">Total Outstanding balance</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody id="set-rows">
                  {currentItems.map((customer, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="table-column-pl-0">
                        <a className="product-list-media">
                          <div className="table--media-body">
                            <h5 className="title m-0">
                              {customer.customer_name}
                            </h5>
                          </div>
                        </a>
                      </td>
                      <td>
                        <h5 className="m-0">
                          <a href="/cdn-cgi/l/email-protection#b7ddd2dadacef7d0dad6dedb99d4d8da">
                            <span
                              className="__cf_email__"
                              data-cfemail="4f252a2222360f28222e2623612c2022"
                            >
                              {customer.customer_email}
                            </span>
                          </a>
                        </h5>
                        <div>
                          <a href="Tel:+8801732323232">
                            {customer.customer_phone_number}
                          </a>
                        </div>
                      </td>
                      <td>
                        <div className="text-center">
                          <span>{customer.total_orders}</span>
                        </div>
                      </td>
                      <td>
                        <div className="text-center">
                          {customer.total_order_amount}
                        </div>
                      </td>
                      <td>
                        <div className="text-center">
                          {customer.total_amount_paid}
                        </div>
                      </td>
                      <td>
                        <div className="text-center">
                          {customer.total_outstanding_balance}
                        </div>
                      </td>
                      <td>
                        <div className="btn--container justify-content-center">
                          {/* <Link className="action-btn" to="#">
                          <MdEdit />
                        </Link> */}
                          <Link
                            className="action-btn"
                            to={`/customer-sales/${customer.id}`}
                          >
                            <MdOutlineRemoveRedEye />
                          </Link>
                          <a
                            className="action-btn btn--danger btn-outline-danger"
                            href="javascript:"
                            onClick={() => deleteCustomer(customer.id)}
                          >
                            <MdDeleteOutline />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div className="card-footer">
            <nav>
              <ReactPaginate
                breakLabel="..."
                nextLabel="›"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="‹"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                activeClassName="page-item active"
                pageLinkClassName="page-link"
                pageClassName="page-item"
                previousClassName="page-item"
                nextClassName="page-item"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
              />
            </nav>
          </div>
          {customers.length <= 0 && !loadingCustomers && (
            <NoData/>
          )}
        </div>
      </div>
    </main>
  );
};

export default CustomerList;
