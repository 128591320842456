import axios from "axios";
import React, { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import { databaseUrl } from "../../utils/data";
import Swal from "sweetalert2";
import { useAuthenticate } from "../../Context/AuthContext";

const ArrearsPayment = ({ sale, setReceivePaymentModal }) => {
  const {authToken} = useAuthenticate()

  const [amountPaid, setAmountPaid] = useState(null);
  const [receivingPayment, setReceivingPayment] = useState(false);
  const [outstandingBalance, setOutstandingBalance] = useState(
    sale?.outstanding_balance
  );

  const receivePayment = (e) => {
    e.preventDefault();
    if (!amountPaid) {
      toast.error("Please enter amount");
      return false;
    }
    setReceivingPayment(false)
    const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${authToken}`,
        },
      };


    const body = JSON.stringify({
      sale: sale?.id,
      amount_paid: amountPaid,
      outstanding_balance: outstandingBalance,
    });

    axios.post(`${databaseUrl}/sales/arrears-payment/`,body, config).then((response)=>{
        setReceivingPayment(false)
        Swal.fire({
            title: "Received!",
            text: "Payment has been received successfully.",
            icon: "success",
          }).then(()=>{
            window.location.reload();
          })
        }).catch((error)=>{
            
            setReceivingPayment(false)
        })
    
  };

  return (
    <div className="modal fade" id="add-customer">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Receive Payment</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                onClick={() => setReceivePaymentModal(false)}
              >
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={(e) => receivePayment(e)}>
              <div className="row">
                <div className="col-12 col-lg-12">
                  <div className="form-group">
                    <label className="input-label">
                      Sale Invoice
                      <span className="input-label-secondary text-danger">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      name="sale"
                      className="form-control"
                      disabled
                      value={sale?.sale_id}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <label className="input-label">Amount Paid</label>
                    <input
                      type="number"
                      step="0.01"
                      name="amount_paid"
                      className="form-control"
                      placeholder="100.00"
                      onChange={(e) => {
                        setAmountPaid(e.target.value);
                        setOutstandingBalance(
                          parseFloat(
                            parseFloat(sale?.outstanding_balance) -
                              e.target.value
                          ).toFixed(2)
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <label className="input-label">
                      Outstanding Balance
                      <span className="input-label-secondary text-danger">
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      name="customer_phone_number"
                      className="form-control"
                      placeholder="Phone"
                      disabled
                      value={outstandingBalance}
                      required
                      min="0"
                    />
                  </div>
                </div>
              </div>
              <div className="btn--container justify-content-end">
                {!receivingPayment ? (
                  <>
                    <button type="reset" className="btn btn--reset">
                      Reset
                    </button>
                    <button
                      type="submit"
                      id="submit_new_customer"
                      className="btn btn--primary"
                    >
                      Submit
                    </button>
                  </>
                ) : (
                  <RotatingLines
                    visible={true}
                    height="96"
                    width="96"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArrearsPayment;
