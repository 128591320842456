import React, { useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { GiMedicines } from "react-icons/gi";
import SidemenuItem from "./SidemenuItem";
import { IoCloseCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { FaCartPlus } from "react-icons/fa";
import { IoPeopleOutline } from "react-icons/io5";
import { ImStatsBars } from "react-icons/im";
import { IoIosPeople } from "react-icons/io";
import { useAuthenticate } from "../Context/AuthContext";
import PharmacyLogo from "../assets/stewards1.png"


const Sidebar = ({ openSidebar, setOpenSidebar }) => {
  const { user } = useAuthenticate();
  const menuItems = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <AiFillHome />,
    },
    {
      path: "/medicine/*",
      name: "Medicine Stock",
      icon: <GiMedicines />,
      submenus: [
        {
          path: "/medicine/list",
          name: "Medicine List",
        },
        {
          path: "/medicine/add",
          name: "Add Medicine",
        },
        {
          path: "/medicine/categories",
          name: "Categories",
        },
        // {
        //     path:"/academics/academic-terms",
        //     name: "Import Medicine"
        // },
      ],
    },
    {
      path: "/sales/*",
      name: "Sales",
      icon: <FaCartPlus />,
      submenus: [
        {
          path: "/sales/new",
          name: "New Sale",
        },
        {
          path: "/sales/list",
          name: "Sales History",
        },
      ],
    },
    {
      path: "/staff-management/*",
      name: "Staff Management",
      icon: <IoPeopleOutline />,
      submenus: [
        {
          path: "/staff-management/employees",
          name: "Employees",
        },
        {
          path: "/staff-management/attendance-details",
          name: "Attendance Report",
        },
        {
          path: "/staff-management/staff-attendance-report",
          name: "Your Attendance Report",
        },
      ],
    },
    {
      path: "/reports/*",
      name: "Report And Analytics",
      icon: <ImStatsBars />,
      submenus: [
        {
          path: "/reports/revenue",
          name: "Revenue Report",
        },
        {
          path: "/reports/expense",
          name: "Expense Report",
        },
        {
          path: "/reports/income-statement",
          name: "Income Statement",
        },
      ],
    },
    {
      path: "/customers/*",
      name: "Customers",
      icon: <IoIosPeople />,
      submenus: [
        {
          path: "/customers/list",
          name: "Customer List",
        },
        {
          path: "/customers/debts",
          name: "Customer Debts",
        },
        {
          path: "/customers/new",
          name: "Add New Customer",
        },
      ],
    },
    {
      path: "/settings/*",
      name: "Settings",
      icon: <IoSettingsOutline />,
      submenus: [
        {
          path: "/settings/system-setup",
          name: "System Setup",
        },
      ],
    },
  ];

  const pharmacistMenuItems = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <AiFillHome />,
    },
    {
      path: "/medicine/*",
      name: "Medicine Stock",
      icon: <GiMedicines />,
      submenus: [
        {
          path: "/medicine/list",
          name: "Medicine List",
        },
        {
            path:"/medicine/add",
            name: "Add Medicine"
        },
        {
            path:"/medicine/categories",
            name: "Categories"
        },
        // {
        //     path:"/academics/academic-terms",
        //     name: "Import Medicine"
        // },
      ],
    },
    {
      path: "/sales/*",
      name: "Sales",
      icon: <FaCartPlus />,
      submenus: [
        {
          path: "/sales/new",
          name: "New Sale",
        },
        {
          path: "/sales/list",
          name: "Sales History",
        },
      ],
    },
    {
      path: "/staff-management/*",
      name: "Staff Management",
      icon: <IoPeopleOutline />,
      submenus: [
        {
          path: "/staff-management/staff-attendance-report",
          name: "Your Attendance Report",
        },
      ],
    },
    {
      path: "/customers/*",
      name: "Customers",
      icon: <IoIosPeople />,
      submenus: [
        {
          path: "/customers/list",
          name: "Customer List",
        },
        {
          path: "/customers/debts",
          name: "Customer Debts",
        },
        {
          path: "/customers/new",
          name: "Add New Customer",
        },
      ],
    },
  ];
  return (
    <aside
      style={{ marginLeft: openSidebar ? "0" : "-16.25rem" }}
      className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered"
    >
      <div className="navbar-vertical-container text-capitalize">
        <div className="navbar-vertical-footer-offset">
          <div className="navbar-brand-wrapper justify-content-between">
            <div className="navbar-brand font-bold" aria-label="Front">
              <img
                className="side-logo"
                src={PharmacyLogo}
                alt="Logo"
              />
              
              <span className="sidebar-close-icon">
                <IoCloseCircleOutline
                  className="ml-5"
                  onClick={() => setOpenSidebar(!openSidebar)}
                />
              </span>
            </div>
          </div>
          <div className="navbar-vertical-content" id="navbar-vertical-content">
            {/* NAVLIST */}
            <ul className="navbar-nav navbar-nav-lg nav-tabs mt-3">
              {user?.employee?.role === "Administrator" ? (
                <>
                  {menuItems.map((menuItem, index) => (
                    <SidemenuItem
                      menuItem={menuItem}
                      key={index}
                      submenus={menuItem?.submenus}
                    />
                  ))}
                </>
              ) : (
                <>
                  {pharmacistMenuItems.map((menuItem, index) => (
                    <SidemenuItem
                      menuItem={menuItem}
                      key={index}
                      submenus={menuItem?.submenus}
                    />
                  ))}
                </>
              )}
            </ul>
            {/* END NAVLIST */}

          </div>
        
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
