
import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Login from './Pages/Login/Login';
import ProtectedRoutes from './utils/ProtectedRoutes';
import Layout from './Components/Layout';
import './Styles/style.css'
import './Styles/styles.css'
import './Styles/theme.css'
import './Styles/toastr.css'
import './Styles/vendor.css'
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from './Context/AuthContext';
import Guest from './utils/Guest';

function App() {
  return (
    <BrowserRouter>
      {/* <AuthProvider></AuthProvider> */}
      <Routes>
        <Route exact path="/" element={<Guest component={Login} />} />
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/*" element={<ProtectedRoutes component={Layout} />} />
      </Routes>

      <ToastContainer
          position="top-right"
          autoClose={6608}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />

    </BrowserRouter>
  );
}

export default App;
