import React, { useEffect, useState } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { databaseUrl, saleHistory } from "../../utils/data";
import ReactPaginate from "react-paginate";
import { useAuthenticate } from "../../Context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";


const RevenueDetails = () => {
    const {revenue_date} = useParams();
    const {authToken} = useAuthenticate()
    const [sales, setSales] = useState([])
    const [saleHistory, setSaleHistory] = useState([])


    const itemsPerPage = 10;
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = sales.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(sales.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % sales.length;
        setItemOffset(newOffset);
    };

    const filterSaleList = (search) => {
    
        if (search === "") {
          setSales(saleHistory);
        } else {
          const filteredSaleList = sales.filter((sale) => {
            return search.toLowerCase() === ""
              ? sale
              : sale?.sale?.sale_id.toString().toLowerCase().includes(search)
          });
          setSales(filteredSaleList);
        }
      };

      const getSaleList = async () => {
        const config = {
          headers:{
            "Content-Type": "application/json",
            "Authorization": `JWT ${authToken}`
          }
        }
        await axios.get(`${databaseUrl}/report/revenue-details/${revenue_date}`, config).then((response)=>{
          setSales(response.data);
          setSaleHistory(response.data);
        }).catch((error)=>{
          
          toast.error("Something went wrong")
        })
      }

      useEffect(()=>{
        getSaleList()
      },[])

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="mb-0 page-header-title">
            <span className="page-header-icon">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/all_orders.png"
                className="w--20"
                alt=""
              />
            </span>
            <span className="">Revenue for {revenue_date}</span>
          </h1>
        </div>
        <div className="card">

          <div className="card-body p-20px">
            <div className="order-top">
              <div className="card--header">
                <form>
                  <div className="input-group">
                    <input
                      id="datatableSearch_"
                      type="search"
                      name="search"
                      className="form-control"
                      placeholder="Ex : Search by invoice ID"
                      aria-label="Search"
                      onChange={(e)=>filterSaleList(e.target.value)}
                      required
                    />
                  </div>
                </form>
               
              </div>
            </div>
            <div className="table-responsive datatable-custom">
              <table
                className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                style={{ width: "100%" }}
              >
                <thead className="thead-light">
                  <tr>
                    <th className="">SL</th>
                    <th className="table-column-pl-0">Invoice ID</th>
                    <th>Date</th>
                    <th>Customer</th>
                    <th>Total amount</th>
                    <th>
                      <div className="text-center">Sale Status</div>
                    </th>
                    <th>
                      <div className="text-center">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody id="set-rows">
                  {currentItems.map((sale, index) => (
                    <tr key={index} className="status-delivered class-all">
                      <td className="">{index + 1}</td>
                      <td className="table-column-pl-0">
                        <a href="https://grofresh-admin.6amtech.com/admin/orders/details/100087">
                          {sale?.sale?.sale_id}
                        </a>
                      </td>
                      <td>{sale.date}</td>

                      <td>
                        {
                          sale?.sale?.customer ? (
                            <>
                              <div>
                                <a
                                  className="text-body text-capitalize font-medium"
                                >
                                  {sale?.sale?.customer?.customer_name}
                                </a>
                              </div>
                              <div className="text-sm">
                                <Link>{sale?.sale?.customer?.customer_phone_number}</Link>
                              </div>
                            </>                            
                          ):(
                            <p>
                              Walk in customer
                            </p>
                          )
                        }
                      </td>

                      <td>
                        <div className="mw-90">
                          <div>GHS {sale.amount_paid}</div>
                        </div>
                      </td>
                      <td className="text-capitalize text-center">
                        <span className="badge badge-soft-success">
                          {" "}
                          {sale?.sale?.payment_status}{" "}
                        </span>
                      </td>
                      <td>
                        <div className="btn--container justify-content-center">
                          <Link
                            className="action-btn btn--primary btn-outline-primary"
                            to={`/sales/${sale?.sale?.id}/details`}
                          >
                            <MdOutlineRemoveRedEye />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer border-0">
            <div className="d-flex justify-content-center justify-content-sm-end">
              <nav>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="›"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="‹"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  activeClassName="page-item active"
                  pageLinkClassName="page-link"
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default RevenueDetails;
