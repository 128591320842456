import React, { useEffect, useRef, useState } from "react";
import { MdEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { useAuthenticate } from "../../Context/AuthContext";
import axios from "axios";
import { databaseUrl } from "../../utils/data";
import ReactToPrint from "react-to-print";
import NoData from "../../Components/NoData";
import { RotatingLines } from "react-loader-spinner";


const MedicineList = () => {
  const { authToken } = useAuthenticate();
  const [gettingMedicineList, setGettingMedicineList] = useState(false)
  const [medicineList, setMedicineList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [totalMedicine, setTotalMedicine] = useState(0);
  const medicineListRef = useRef();

  

  const getMedicineList = async (page, searchQuery) => {
    setGettingMedicineList(true)
    const config = {
      "headers":{
        "Content-Type": "multipart/form-data",
        'Authorization':`JWT ${authToken}`
      }
    }
  
  await axios.get(`${databaseUrl}/inventory/medicine/?page=${page}&search=${searchQuery}`, config).then((response)=>{
    setMedicineList(response.data?.results);
    setNextPage(response.data?.next);
    setPrevPage(response.data?.previous)
    setTotalPages(Math.ceil(response.data?.count / 20))
    setTotalMedicine(response.data?.count);
    setGettingMedicineList(false)
  }).catch((error)=>{
      console.clear()
      setGettingMedicineList(false)
  })
  };

  const filterDrugList = (searchValue) => {
    setSearch(searchValue);
    setCurrentPage(1)
    
  };

  const deleteMedicine = (medicine_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `JWT ${authToken}`,
          },
        };
        axios
          .delete(`${databaseUrl}/inventory/medicine/${medicine_id}/`, config)
          .then(() => {
            Swal.fire({
              title: "Deleted!",
              text: "Medicine has been deleted.",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          });
      }
    });
  };

  const handleNextPage = () => {
    if (nextPage){
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if(prevPage){
      setCurrentPage(currentPage - 1)
    }
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalNumbersToShow = 3;
    const totalPagesToShow = 5;

    let startPage = Math.max(1, currentPage - totalNumbersToShow);
    let endPage = Math.min(totalPages, currentPage + totalNumbersToShow);

    if(startPage > 1){
      pageNumbers.push(1);
      if (startPage > 2){
        pageNumbers.push('...')
      }
    }

    for(let i =  startPage; i <= endPage; i++){
      pageNumbers.push(i)
    }

    if(endPage < totalPages){
      if(endPage < totalPages - 1){
        pageNumbers.push('...')
      }
      pageNumbers.push(totalPages)
    }

    return pageNumbers.map(number => (
      <li
        key={number}
        onClick={()=>number !== "..." & handlePageClick(number)}
        className = {number === currentPage ? 'page-item active' : 'page-item'}
      > 
        <a className="page-link">
          {number}
        </a>
      </li>
    ))

  }

  useEffect(() => {
    getMedicineList(currentPage, search);
  }, [currentPage, search]);

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="mb-0 page-header-title">
            
            <span className="">Medicine List</span>
          </h1>
        </div>
        <div className="card">
          <div className="card-body p-5px">
            <div className="order-top">
              <div className="card--header">
                <form
                  
                >
                  <div className="input-group">
                    <input
                      id="datatableSearch_"
                      type="search"
                      name="search"
                      className="form-control"
                      placeholder="Search by Drug Name or Generic Name"
                      aria-label="Search"
                      required
                      onChange={(e) => filterDrugList(e.target.value)}
                    />
                    
                  </div>
                </form>

                <div className="hs-unfold mr-2">
                  <ReactToPrint
                    trigger={() => (
                      <div className="btn btn-primary">Print List</div>
                    )}
                    content={() => medicineListRef.current}
                  />
                </div>
              </div>
            </div>
            <div className="table-responsive datatable-custom">
              <table
                className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                style={{ width: "100%" }}
              >
                <thead className="thead-light">
                  <tr>
                    <th className="">SL</th>
                    <th className="table-column-pl-0">Medicine ID</th>
                    <th>Name</th>
                    <th>Generic Name</th>
                    <th>Expiry Date</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>

                    <th>
                      <div className="text-center">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody id="set-rows">
                  {medicineList.map((medicine, index) => (
                    <tr className="status-delivered class-all">
                      <td className="">{index + 1}</td>
                      <td className="table-column-pl-0">
                        <Link href="#">{medicine.medicine_id}</Link>
                      </td>
                      <td>{medicine.name}</td>
                      <td>{medicine.generic_name}</td>
                      <td>{medicine?.expiry_date}</td>
                      <td>
                        <span>{medicine.quantity}</span>
                      </td>
                      <td>
                        <div>
                          <Link
                            className="text-body text-capitalize font-medium"
                            to="#"
                          >
                            {medicine.final_price}
                          </Link>
                        </div>
                      </td>

                      <td>
                        <div className="btn--container justify-content-center">
                          <Link
                            to={`/medicine/${medicine.id}/details`}
                            className="action-btn"
                            title="Edit"
                          >
                            <MdEdit />
                          </Link>
                          <Link className="action-btn btn--danger btn-outline-danger">
                            <MdDeleteOutline
                              onClick={() => deleteMedicine(medicine.id)}
                            />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {medicineList.length <= 0 && !gettingMedicineList && (
                <NoData />
              )}
              {
                gettingMedicineList && (
                  <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <RotatingLines
                    visible={true}
                    height="46"
                    width="46"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                  </div>
                )
              }
            </div>
            {/* TABLE TO PRINT */}
            <div style={{ display: "none" }}>
              <table
                ref={medicineListRef}
                className="table table-hover table-bordered table-thead-bordered table-nowrap table-align-middle card-table"
                style={{ width: "100%", marginTop: "100px" }}
              >
                <thead className="thead-light">
                  <tr>
                    <th className="">SL</th>
                    <th className="table-column-pl-0">Medicine ID</th>
                    <th>Name</th>
                    <th>Generic Name</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                  </tr>
                </thead>
                <tbody id="set-rows">
                  {medicineList.map((medicine, index) => (
                    <tr className="status-delivered class-all" key={index}>
                      <td className="">{index + 1}</td>
                      <td className="table-column-pl-0">
                        {medicine.medicine_id}
                      </td>
                      <td>{medicine.name}</td>
                      <td>{medicine.generic_name}</td>
                      <td>
                        <span>{medicine.quantity}</span>
                      </td>
                      <td>
                        <div>{medicine.final_price}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer border-0">
            <div className="d-flex justify-content-center justify-content-sm-end">
              {/* PAGINATION */}
              <nav style={{display:"flex", alignItems:"center", justifyContent:"space-between", width:"100%"}}>
                <p>
                  Page {currentPage} of {totalPages}
                </p>
                <ul className="pagination">
                  
                  <li
                    className={!prevPage ? "page-item disabled" : "page-item"}
                    
                    aria-label="« Previous"
                  >
                    <a onClick={handlePreviousPage} className="page-link" aria-hidden="true">
                      ‹
                    </a>
                  </li>
                  {renderPageNumbers()}
                  
                  
                  <li className={!nextPage ? "page-item disabled" : "page-item"}>
                    <a
                      className="page-link"
                      onClick={handleNextPage}
                      rel="next"
                      aria-label="Next »"
                    >
                      ›
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default MedicineList;
