import React from 'react'
import { Navigate } from 'react-router-dom';
import { useAuthenticate } from '../Context/AuthContext';

const Guest = ({component: Component, ...rest}) => {
    const { user } = useAuthenticate();
    if (!user || user === 'undefined' || user === undefined){
        return <Component {...rest} />
    }else{
        return <Navigate to="/dashboard" />
    }
}

export default Guest