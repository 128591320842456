import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { FaCartPlus } from "react-icons/fa";
import { toast } from 'react-toastify';
import LogoSmall from "../../assets/stewards_small.png"

const SaleDrugDetailsModal = ({drug, cart, setCart, setAddMedicineModal, setSubtotal, subtotal,totalTax, setTotalTax,totalCost, setTotalCost}) => {
    const [cartItem, setCartItem] = useState({
        drug:drug?.id,
        drug_name:drug?.name,
        drug_image:drug?.medicine_image,
        drug_unit_price:drug?.unit_price,
        drug_final_price:drug?.final_price,
        quantity:1,
        total_cost:drug?.final_price,
    })

    const reduceQuantity = () => {
        if(parseInt(cartItem?.quantity) > 1) {
            const new_quantity = parseInt(cartItem?.quantity) - 1
            const total_cost = parseFloat(parseFloat(drug?.final_price) * new_quantity).toFixed(2)
            setCartItem({
                ...cartItem,
                ["quantity"]: new_quantity,
                ['total_cost']: total_cost
            })
        }
    }

    const increaseQuantity = () => {
        if(parseInt(cartItem?.quantity) < drug?.quantity){
            const new_quantity = parseInt(cartItem?.quantity) + 1
            const total_cost = parseFloat(parseFloat(drug?.final_price) * new_quantity).toFixed(2)
            setCartItem({
                ...cartItem,
                ["quantity"]: new_quantity,
                ['total_cost']: total_cost
            })
            
        }
    }

    const updateQuantity = (e) => {
        const new_quantity = parseInt(e.target.value)
        const total_cost = parseFloat(parseFloat(drug?.final_price) * new_quantity).toFixed(2)
        setCartItem({
            ...cartItem, 
            [e.target.name]: parseInt(e.target.value),
            ['total_cost']: total_cost
        })
    }

    const addToCart = (e) => {
      e.preventDefault();

      if(cartItem['quantity'] > drug?.quantity){
        return toast.error("Quantity entered is greater than quantity left in stock")
      }

      // check if drug is already in cart
      const cartData = [...cart]
      for (let i = 0; i < cartData.length; i++) {
        if (cartData[i]?.drug === cartItem?.drug){
          toast.error("Item is already in cart");
          setAddMedicineModal(false);
          return false;
        }
      }
      
      // const final_quantity = parseInt(cartItem?.quantity)
      // const initial_unit_tax_amount  = parseFloat(drug?.final_price) - parseFloat(drug?.unit_price)
      // const final_tax_amount = final_quantity * initial_unit_tax_amount
      // setCartItem({
      //   ...cartItem,
      //   ['tax_amount']: final_tax_amount
      // })
      
      setCart([...cart, cartItem]);
      // const subTotal = parseFloat(parseFloat(cartItem?.drug_unit_price)*cartItem?.quantity + parseFloat(subtotal)).toFixed(2)
      const mainTotal = parseFloat(parseFloat(cartItem?.total_cost) + parseFloat(totalCost)).toFixed(2)
      // const newTotalTax = parseFloat(parseFloat(totalTax) + final_tax_amount).toFixed(2)
      // setSubtotal(subTotal)
      // setTotalTax(newTotalTax)
      setTotalCost(mainTotal)
      toast.success("Drug Added Successfully!")
      setAddMedicineModal(false);
    }

  return (
    <div className="modal fade" id="quick-view" tabIndex={-1} onClick={()=>setAddMedicineModal(false)}>
        <div className="modal-dialog" onClick={(e)=>e.stopPropagation()}>
          <div className="modal-content" id="quick-view-modal" style={{minWidth:"500px"}}>
            <div className="modal-body position-relative" >
              <button onClick={()=>setAddMedicineModal(false)} type="button" className="close call-when-done">
                <span>x</span>
              </button>
              <div className="modal--media">
                <div className="modal--media-avatar">
                  <img src={`${drug?.medicine_image ? "https://api.stewardspharmacy.com/" + drug?.medicine_image : LogoSmall}`} alt="" className="img-responsive" />
                </div>
                <div className="details">
                  <span className="product-name">
                    <Link to="" className="h3 mb-2 product-title">
                      {drug?.name}
                    </Link>
                  </span>
                  <div className="mb-3 text-dark">
                    <span className="h3 font-weight-normal text-accent mr-1">
                      GHS {drug?.final_price}
                    </span>
                    <br />
                    <span className="h3 font-weight-normal text-accent mr-1">
                      Quantity Left: {drug?.quantity}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row pt-2">
                <div className="col-12">
                  <h2>Description</h2>
                  <div className="d-block text-break text-dark __description-txt __not-first-hidden">
                    <div>
                      {drug?.description}
                    </div>
                  </div>
                  <form onSubmit={(e)=>addToCart(e)} id="add-to-cart-form" className="mb-2">
                    <div
                      className="d-flex justify-content-between"
                      id="product_qty_div"
                    >
                      <input
                        type="hidden"
                        name=""
                        id="product_stock_count_input"
                      />
                      <div className="product-description-label mt-2 text-dark h3">
                        Quantity:
                      </div>
                      <div className="product-quantity d-flex align-items-center">
                        <div
                          className="input-group input-group--style-2 pr-3"
                          style={{ width: "160px" }}
                        >
                          <span className="input-group-btn">
                            <button
                              class="btn btn-number text-dark"
                              type="button"
                              id="minus_btn"
                              data-type="minus"
                              data-field="quantity"
                              disabled={parseInt(cartItem?.quantity) <= 1}
                              style={{padding: "10px"}}
                            >
                              <IoIosRemove onClick={()=>reduceQuantity()} />
                            </button>
                          </span>
                          <input
                            type="text"
                            name="quantity"
                            class="form-control input-number text-center cart-qty-field"
                            value={cartItem?.quantity}
                            min="1"
                            max={drug?.quantity}
                            onChange={(e)=>updateQuantity(e)}
                          />
                          <span class="input-group-btn">
                            <button
                              class="btn btn-number text-dark"
                              type="button"
                              data-type="plus"
                              id="plus_btn"
                              data-field="quantity"
                              style={{padding: "10px"}}
                            >
                              {/* <i class="tio-add  font-weight-bold"></i> */}
                              <IoIosAdd onClick={()=>increaseQuantity()} />
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row no-gutters mt-2 text-dark"
                      id="chosen_price_div"
                    >
                      <div class="col-2">
                        <div class="product-description-label">
                          Total Price:
                        </div>
                      </div>
                      <div class="col-10">
                        <div class="product-price">
                          <strong id="chosen_price">GHS {cartItem?.total_cost}</strong>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center mt-2">
                      <button
                        class="btn btn-primary"
                        id="add_to_cart_btn"
                        type="submit"
                        style={{width:"37%", height: "45px"}}
                      >
                        <FaCartPlus className='mr-2' />
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default SaleDrugDetailsModal