import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import axios from "axios";
import { databaseUrl } from "../../utils/data";
import { toast } from "react-toastify";
import { useAuthenticate } from "../../Context/AuthContext";
import Loading from "../../Components/Loading";
import NewExpenseModal from "./NewExpenseModal";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RotatingLines } from "react-loader-spinner";
import ExpenseDetails from "./ExpenseDetails";
import ReactToPrint from "react-to-print";
import NoData from "../../Components/NoData";

ChartJS.register(CategoryScale, LinearScale, BarElement);

const ExpenseReport = () => {
  const [expenseData, setExpenseData] = useState();
  const expenseListRef = useRef();
  const [loadingData, setLoadingData] = useState(true);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [expenseList, setExpenseList] = useState([]);
  const [recordExpenseModal, setRecordExpenseModal] = useState(false);
  const { authToken } = useAuthenticate();
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loadingExpenseList, setLoadingExpenseList] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null)
  const [expenseModal, setExpenseModal] =  useState(false);

  // PAGINATION SETTINGS
  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = expenseList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(expenseList.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % expenseList.length;
    setItemOffset(newOffset);
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const options = {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: "#e7eaf3",
            drawBorder: false,
            zeroLineColor: "#e7eaf3",
          },
          ticks: {
            beginAtZero: true,
            fontSize: 12,
            fontColor: "#97a4af",
            fontFamily: "Open Sans, sans-serif",
            padding: 5,
            postfix: "GHS",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            fontSize: 12,
            fontColor: "#97a4af",
            fontFamily: "Open Sans, sans-serif",
            padding: 5,
          },
          categoryPercentage: 0.3,
          maxBarThickness: "10",
        },
      ],
    },
    cornerRadius: 5,
    tooltips: {
      prefix: " ",
      hasIndicator: true,
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
  };

  const getExpenseOverview = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authToken}`,
      },
    };

    await axios
      .get(`${databaseUrl}/report/expense-overview`, config)
      .then((response) => {
        setExpenseData(response.data);
        setExpenseList(response.data?.expense_list);
        setData({
          labels,
          datasets: [
            {
              label: "Total expense amount",
              data: response.data?.expense_statistics.map(
                (data) => data?.amount
              ),
              // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
              backgroundColor: "#a2ceee",
              hoverBackgroundColor: "#0177cd",
              borderColor: "#a2ceee",
            },
          ],
        });
        setLoadingData(false);
      })
      .catch((error) => {
        setLoadingData(false);
        
        toast.error("Something went wrong");
      });
  };

  const getExpenseTypes = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authToken}`,
      },
    };

    await axios
      .get(`${databaseUrl}/report/expense-types`, config)
      .then((response) => {
        setExpenseTypes(response.data);
      })
      .catch((error) => {});
  };

  const filterExpenseList = async (e) => {
    e.preventDefault();
    setLoadingExpenseList(true)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authToken}`,
      },
    };

    await axios
      .get(
        `${databaseUrl}/report/expense-list/?date__gte=${startDate}&date__lte=${endDate}`,
        config
      )
      .then((response) => {
        setExpenseList(response.data);
        setLoadingExpenseList(false)
      })
      .catch((error) => {
        setLoadingExpenseList(false)
      });
  };

  useEffect(() => {
    getExpenseOverview();
    getExpenseTypes();
  }, []);

  if (loadingData) {
    <Loading />;
  } else {
    return (
      <main id="content" role="main" className="main main-page pointer-event">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="media align-items-center">
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/expense_report.png"
                className="w--20"
                alt=""
              />
              <div className="media-body pl-3">
                <h1 className="page-header-title mb-1">Expense Report</h1>
              </div>
            </div>
          </div>
          <div>
            <div className="card">
              <div className="card-header border-0">
                <div className="w-100 pt-3">
                  <form
                    className="w-100"
                    onSubmit={(e) => filterExpenseList(e)}
                  >
                    <div className="row g-3 g-sm-4 g-md-3 g-lg-4">
                      <div
                        className="col-sm-6 col-md-4 col-lg-3"
                        id="start_date_div"
                      >
                        <div className="input-date-group">
                          <label className="input-label" htmlFor="start_date">
                            Start Date
                          </label>
                          <label className="w-100">
                            <input
                              type="date"
                              id="start_date"
                              name="start_date"
                              required
                              className="js-flatpickr form-control flatpickr-custom min-h-45px"
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>
                      <div
                        className="col-sm-6 col-md-4 col-lg-3"
                        id="end_date_div"
                      >
                        <div className="input-date-group">
                          <label className="input-label" htmlFor="end_date">
                            End Date
                          </label>
                          <label className="w-100">
                            <input
                              type="date"
                              id="end_date"
                              name="end_date"
                              required
                              onChange={(e) => setEndDate(e.target.value)}
                              className="js-flatpickr form-control flatpickr-custom min-h-45px"
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 __btn-row">
                        <button
                          type="reset"
                          id=""
                          className="btn w-100 btn--reset min-h-45px"
                        >
                          Clear
                        </button>
                        <button
                          type="submit"
                          id="show_filter_data"
                          className="btn w-100 btn--primary min-h-45px"
                        >
                          Show data
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="col-md-12 pt-4">
                    <div className="report--data"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="store-report-content mb-5 mt-4">
              <div className="left-content expense--content">
                <div className="left-content-card">
                  <img
                    src="https://grofresh-admin.6amtech.com/public/assets/admin/img/expense.svg"
                    alt=""
                  />
                  <div className="info">
                    <h4 className="subtitle">
                      GHS {expenseData?.total_expense}
                    </h4>
                    <h6 className="subtext">
                      <span>Total Expense</span>
                    </h6>
                  </div>
                </div>
                <div className="left-content-card">
                  <img
                    src="https://grofresh-admin.6amtech.com/public/assets/admin/img/free-delivery.svg"
                    alt=""
                  />
                  <div className="info">
                    <h4 className="subtitle">
                      GHS {expenseData?.this_week_expense}
                    </h4>
                    <h6 className="subtext">This Week</h6>
                  </div>
                </div>
                <div className="left-content-card">
                  <img
                    src="https://grofresh-admin.6amtech.com/public/assets/admin/img/free-delivery.svg"
                    alt=""
                  />
                  <div className="info">
                    <h4 className="subtitle">
                      GHS {expenseData?.this_month_expense}
                    </h4>
                    <h6 className="subtext">This Month</h6>
                  </div>
                </div>
                <div className="left-content-card">
                  <img
                    src="https://grofresh-admin.6amtech.com/public/assets/admin/img/coupon-discount.svg"
                    alt=""
                  />
                  <div className="info">
                    <h4 className="subtitle">
                      GHS {expenseData?.this_year_expense}
                    </h4>
                    <h6 className="subtext">
                      <span>This Year</span>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="card center-chart-area">
                <div className="px-3 py-2">
                  <div className="d-flex flex-wrap gap-3 align-items-center">
                    <h4 className="mb-0 mr-auto">All Expenses</h4>

                    <button
                      className="btn btn-outline-primary"
                      onClick={() => setRecordExpenseModal(true)}
                    >
                      New Expense
                    </button>
                    
                    <ReactToPrint
                      trigger={()=>(
                        <button
                          className="btn btn--primary"
                          onClick={() => setRecordExpenseModal(true)}
                        >
                          Print List
                        </button>
                      )}
                      content={()=>expenseListRef.current}
                    />
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table __table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100">
                    <thead className="thead-light thead-50 text-capitalize">
                      <tr>
                        <th>SL</th>
                        <th>Expense Type</th>
                        <th>Date</th>
                        <th className="text-center">Expense Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {
                      !loadingExpenseList && (
                          <tbody>
                            {currentItems.map((expense, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{expense.expense_type}</td>
                                <td>{expense.date}</td>
                                <td className="text-center">GHS {expense.amount}</td>
                                <td>
                                  <Link onClick={()=>{setSelectedExpense(expense); setExpenseModal(true)}} className="action-btn btn--primary btn-outline-primary">
                                    <MdOutlineRemoveRedEye />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>

                      )
                    }
                  </table>
                  <div className="card-footer border-0">
                    <div className="d-flex justify-content-center justify-content-sm-end"></div>
                  </div>
                  {expenseList.length <= 0 && (
                    <NoData />
                  )}
                  {loadingExpenseList && (
                    <div className="text-center">
                      <RotatingLines
                        visible={true}
                        height="46"
                        width="46"
                        color="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  )}
                </div>

                {/* LIST TO PRINT */}
                <div style={{display:"none"}}>
                  <table style={{marginTop:"50px"}} ref={expenseListRef} className="table __table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100">
                      <thead className="thead-light thead-50 text-capitalize">
                        <tr>
                          <th>SL</th>
                          <th>Expense Type</th>
                          <th>Date</th>
                          <th className="text-center">Expense Amount</th>
                          
                        </tr>
                      </thead>
                      {
                        !loadingExpenseList && (
                            <tbody>
                              {expenseList.map((expense, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{expense.expense_type}</td>
                                  <td>{expense.date}</td>
                                  <td className="text-center">GHS {expense.amount}</td>
                                  
                                </tr>
                              ))}
                            </tbody>

                        )
                      }
                    </table>
                </div>
                <div className="card-footer border-0">
                  <div className="d-flex justify-content-center justify-content-sm-end">
                    <nav>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="›"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="‹"
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        activeClassName="page-item active"
                        pageLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                      />
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="center-chart-area">
              <div className="center-chart-header">
                <h3 className="title">Transaction Statistics</h3>
              </div>

              {/* BARCHART */}
              <Bar options={options} data={data} />
            </div>
          </div>
        </div>
        {recordExpenseModal && (
          <NewExpenseModal
            expenseTypes={expenseTypes}
            setRecordExpenseModal={setRecordExpenseModal}
          />
        )}
        {
          expenseModal && (
            <ExpenseDetails setExpenseModal={setExpenseModal} expense={selectedExpense} />
          )
        }
      </main>
    );
  }
};

export default ExpenseReport;
