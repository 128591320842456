import React, { useState } from "react";
import { IoIosNotifications, IoMdMenu } from "react-icons/io";
import { useAuthenticate } from "../Context/AuthContext";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { databaseUrl } from "../utils/data";
import { toast } from "react-toastify";


const Topbar = ({ openSidebar, setOpenSidebar }) => {
  const [dropdown, setDropdown] = useState(false);
  const { authToken,user,pharmacySetup } = useAuthenticate();
  const [notificationDropdown, setNotificationDropdown] = useState(false);

  const logoutUser = () => {
    Swal.fire({
      title: 'Do you want to logout ',
      showDenyButton: true,
      confirmButtonColor: '#01684b',
      cancelButtonColor: '#363636',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      }).then((result) => {
      if (result.value) {
        localStorage.removeItem("auth")
        window.location.href='/';
      } else{
          Swal.fire({
          title: 'Canceled',
          confirmButtonText: 'Okay',
          })
      }

      })
  }

  const markAsRead = async (notification_id) => {
    const body = JSON.stringify({
      is_read: true,
    })

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authToken}`,
      },
    };

    await axios.put(`${databaseUrl}/account/notification/${notification_id}/`,body, config).then((response)=>{
      toast.success("Marked as read");
    }).catch((error) => {
      console.log(error)
    });

  }

  return (
    <header
      id="header"
      className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered"
    >
      <div className="navbar-nav-wrap">
        <div className="navbar-nav-wrap-content-left d-xl-none">
          <button
            type="button"
            className="js-navbar-vertical-aside-toggle-invoker close mr-3"
            onClick={() => setOpenSidebar(!openSidebar)}
          >
            <IoMdMenu className="tio-first-page navbar-vertical-aside-toggle-short-align" />
          </button>
        </div>
        <div className="navbar-nav-wrap-content-right">
          <ul className="navbar-nav align-items-center flex-row">
          <li class="nav-item">
              <div class="hs-unfold">
                <a
                  onClick={() => {
                    setNotificationDropdown(!notificationDropdown);
                    setDropdown(false);
                  }}
                  class="js-hs-unfold-invoker btn btn-icon notify--icon"
                  data-hs-unfold-invoker=""
                >
                  <IoIosNotifications style={{ fontSize: "2rem" }} />
                  <span class="amount">{pharmacySetup?.notifications?.length}</span>
                </a>
                {notificationDropdown && (
                  <div
                    id="accountNavbarDropdown"

                    
                    className="notification-dropdown hs-unfold-content dropdown-unfold dropdown-menu notification-dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account hs-unfold-content-initialized hs-unfold-css-animation animated slideInUp"
                  >
                    <div className="dropdown-divider" />
                    {
                      pharmacySetup?.notifications?.map((notification, index)=>(
                        <>
                          <div onClick={()=>markAsRead(notification?.id)} className={`notification-dropdown-item px-4 py-2 cursor-pointer ${!notification?.is_read && "font-bold"}`} key={index}>
                            {notification?.details}
                          </div>
                          <div className="dropdown-divider" />
                        </>
                      ))
                    }
                  </div>
                )}
              </div>
            </li>
            <li className="nav-item ml-4">
              <div className="hs-unfold">
                <a onClick={()=>{setDropdown(!dropdown);setNotificationDropdown(false)}} className="cursor-pointer js-hs-unfold-invoker navbar-dropdown-account-wrapper">
                  <div className="cmn--media d-flex align-items-center">
                    <div className="media-body pl-0 pr-2">
                      <span className="card-title h5 text-right">
                        {" "}
                        {user?.employee?.full_name}{" "}
                      </span>
                      <span className="card-text">{user?.employee?.role}</span>
                    </div>
                    <div className="avatar avatar-sm avatar-circle">
                      <img
                        className="avatar-img"
                        src={user?.employee?.profile_picture ? `https://api.stewardspharmacy.com/${user?.employee?.profile_picture}` : "https://grofresh-admin.6amtech.com/storage/app/public/admin/2023-10-21-65335c1bd07bd.png"}
                        alt="no data"
                      />
                      
                      <span className="avatar-status avatar-sm-status avatar-status-success" />
                    </div>
                  </div>
                </a>
                {
                  dropdown && (
                    <div
                      id="accountNavbarDropdown"
                      className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account w-16rem hs-unfold-content-initialized hs-unfold-css-animation animated slideInUp"
                    >
                      <div className="dropdown-item-text">
                        <div className="media align-items-center">
                          <div className="avatar avatar-sm avatar-circle mr-2">
                            <img
                              className="avatar-img"
                              src={user?.employee?.profile_picture ? `https://api.stewardspharmacy.com/${user?.employee?.profile_picture}` : "https://grofresh-admin.6amtech.com/storage/app/public/admin/2023-10-21-65335c1bd07bd.png"}
                              alt="no data"
                            />
                          </div>
                          <div className="media-body">
                            <span className="card-title h5">{user?.employee?.full_name}</span>
                            <span className="card-text">
                              <a
                                className="__cf_email__"
                              >
                                {/* info@stewardspharmacy.com */}
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown-divider" />
                      <Link
                        className="dropdown-item"
                        to="/settings/profile"
                      >
                        <span className="text-truncate pr-2" title="Settings">
                          Profile
                        </span>
                      </Link>
                      <div className="dropdown-divider" />
                      <a
                        className="dropdown-item cursor-pointer"
                        onClick={()=>logoutUser()}
                      >
                        <span className="text-truncate pr-2" title="Sign out">
                          Sign out
                        </span>
                      </a>
                    </div>
                  )
                }
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Topbar;
