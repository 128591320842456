import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
            <div className="row justify-content-between align-items-center">
              <div className="col">
                <p className="font-size-sm mb-0">
                  <span className="d-none d-sm-inline-block">
                    Copyright © {currentYear}, Stewards Pharmacy
                  </span>
                </p>
              </div>
              <div className="col-auto">
                <div className="d-flex justify-content-end">
                  <ul className="list-inline list-separator">
                    {/* <li className="list-inline-item">
                      <Link
                        className="list-separator-link"
                        to=""
                      >
                        Pharmacy Settings
                      </Link>
                    </li> */}
                    <li className="list-inline-item">
                      <Link
                        className="list-separator-link"
                        to="/settings/profile"
                      >
                        Profile
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <label className="badge badge-success text-capitalize">
                        <Link to="https://epahubb.com" style={{color:"inherit"}}>
                            Developed By Epahubb Consult
                        </Link>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
  )
}

export default Footer