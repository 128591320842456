import React, { useEffect, useRef, useState } from "react";
import { MdDeleteOutline, MdOutlineRemoveRedEye } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useAuthenticate } from "../../Context/AuthContext";
import axios from "axios";
import { databaseUrl } from "../../utils/data";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import NoData from "../../Components/NoData";

const SalesHistory = () => {
  const { authToken } = useAuthenticate();
  const salesListRef = useRef();
  const [loadingSaleHistory, setLoadingSaleHistory] = useState(false);
  const [saleHistory, setSaleHistory] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [totalSales, setTotalSales] = useState(0)
  const [salesCount, setSalesCount] = useState(0)

  const filterSaleList = (searchValue) => {
    setSearch(searchValue);
    setCurrentPage(1);
  };

  const getSaleHistory = async (page, searchQuery) => {
    setLoadingSaleHistory(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authToken}`,
      },
    };

    await axios
      .get(
        `${databaseUrl}/sales?search=${searchQuery}&page=${page}&start_date=${filterStartDate}&end_date=${filterEndDate}`,
        config
      )
      .then((response) => {
        setTotalSales(response.data?.total_sales)
        setSaleHistory(response.data?.payload?.results);
        setSalesList(response.data?.payload?.results);
        setNextPage(response.data?.payload?.next);
        setPrevPage(response.data?.payload?.previous);
        setSalesCount(response.data?.payload?.count)
        setTotalPages(Math.ceil(response.data?.payload?.count / 20));
        setLoadingSaleHistory(false);
      })
      .catch((error) => {
        setLoadingSaleHistory(false);
      });
  };

  const filterSaleHistory = async (e) => {
    e.preventDefault();
    if (filterStartDate === "" && filterEndDate === "") {
      toast.error("Please specify the start date and end date");
      return false;
    }
    setLoadingSaleHistory(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authToken}`,
      },
    };

    await axios
      .get(
        `${databaseUrl}/sales/list/?date__gte=${filterStartDate}&date__lte=${filterEndDate}`,
        config
      )
      .then((response) => {
        setSaleHistory(response.data);
        setSalesList(response.data);
        setLoadingSaleHistory(false);
      })
      .catch((error) => {
        setLoadingSaleHistory(false);
      });
  };

  const clearData = () => {
    setFilterStartDate("");
    setFilterEndDate("");
    getSaleHistory();
  };

  const deleteSale = (sale_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `JWT ${authToken}`,
          },
        };
        try {
          await axios
            .delete(`${databaseUrl}/sales/${sale_id}/details`, config)
            .then((response) => {
              Swal.fire({
                title: "Deleted!",
                text: "Sale has been deleted.",
                icon: "success",
              }).then(() => {
                getSaleHistory();
              });
            });
        } catch (error) {
          toast.error("Something went wrong.");
        }
      }
    });
  };

  const handleNextPage = () => {
    if (nextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (prevPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalNumbersToShow = 3;
    const totalPagesToShow = 5;

    let startPage = Math.max(1, currentPage - totalNumbersToShow);
    let endPage = Math.min(totalPages, currentPage + totalNumbersToShow);

    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push("...");
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((number) => (
      <li
        key={number}
        onClick={() => (number !== "...") & handlePageClick(number)}
        className={number === currentPage ? "page-item active" : "page-item"}
      >
        <a className="page-link">{number}</a>
      </li>
    ));
  };

  useEffect(() => {
    getSaleHistory(currentPage, search);
  }, [currentPage, search, filterStartDate, filterEndDate]);

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="mb-0 page-header-title">
            <span className="">All Sales</span>
          </h1>
        </div>
        <div className="row g-2" id="order_stats">
          <div className="col-sm-6 col-lg-3 mb-5">
            <Link className="dashboard--card h-100" to="/medicine/list">
              <h6 className="subtitle">Total Sales</h6>
              <h2 className="title">GHS {totalSales}</h2>
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/dashboard/pending.png"
                alt=""
                className="dashboard-icon"
              />
            </Link>
          </div>

          <div className="col-sm-6 col-lg-3 mb-5">
            <Link className="dashboard--card h-100" to="/medicine/list">
              <h6 className="subtitle">Number of Sales</h6>
              <h2 className="title">{salesCount}</h2>
              <img
                src="https://grofresh-admin.6amtech.com/public/assets/admin/img/dashboard/pending.png"
                alt=""
                className="dashboard-icon"
              />
            </Link>
          </div>
          
        </div>
        <div className="card">
          <div className="card-header shadow flex-wrap p-20px border-0">
            <h5 className="form-bold w-100 mb-3">Select Date Range</h5>
            <form onSubmit={(e) => filterSaleHistory(e)} className="w-100 mt-5">
              <div className="row g-3 g-sm-4 g-md-3 g-lg-4">
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="input-date-group">
                    <label className="input-label" htmlFor="start_date">
                      Start Date
                    </label>
                    <label className="w-100">
                      <input
                        type="date"
                        id="start_date"
                        name="start_date"
                        value={filterStartDate}
                        className="js-flatpickr form-control flatpickr-custom min-h-45px"
                        onChange={(e) => setFilterStartDate(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="input-date-group">
                    <label className="input-label" htmlFor="end_date">
                      End Date
                    </label>
                    <label className="w-100">
                      <input
                        type="date"
                        id="end_date"
                        name="end_date"
                        value={filterEndDate}
                        className="js-flatpickr form-control flatpickr-custom min-h-45px"
                        onChange={(e) => setFilterEndDate(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="card-body p-20px">
            <div className="order-top">
              <div className="card--header">
                <form>
                  <div className="input-group">
                    <input
                      id="datatableSearch_"
                      type="search"
                      name="search"
                      className="form-control"
                      placeholder="Ex : Search by invoice ID"
                      aria-label="Search"
                      onChange={(e) => filterSaleList(e.target.value)}
                    />
                  </div>
                </form>
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-primary">Print List</button>
                  )}
                  content={() => salesListRef.current}
                />
              </div>
            </div>
            <div className="table-responsive datatable-custom">
              {loadingSaleHistory ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "2rem",
                  }}
                >
                  <RotatingLines
                    visible={true}
                    height="96"
                    width="96"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <table
                  className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                  style={{ width: "100%" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th className="">SL</th>
                      <th className="table-column-pl-0">Invoice ID</th>
                      <th>Date</th>
                      <th>Customer</th>
                      <th className="text-right">Total amount</th>
                      <th className="text-right">Amount Paid</th>
                      <th className="text-right">Outstanding balance</th>
                      <th>
                        <div className="text-center">Payment Status</div>
                      </th>
                      <th>
                        <div className="text-center">Action</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody id="set-rows">
                    {saleHistory.map((sale, index) => (
                      <tr key={index} className="status-delivered class-all">
                        <td className="">{index + 1}</td>
                        <td className="table-column-pl-0">{sale.sale_id}</td>
                        <td>{sale.date}</td>

                        <td>
                          {!sale?.customer ? (
                            "Walk in customer"
                          ) : (
                            <>
                              <div>
                                <a
                                  className="text-body text-capitalize font-medium"
                                  href=""
                                >
                                  {sale?.customer?.customer_name}
                                </a>
                              </div>
                              <div className="text-sm">
                                <Link
                                  to={`tel:${sale?.customer?.customer_phone_number}`}
                                >
                                  {sale?.customer?.customer_phone_number}
                                </Link>
                              </div>
                            </>
                          )}
                        </td>

                        <td className="text-right">GHS {sale.total_cost}</td>
                        <td className="text-right">GHS {sale.amount_paid}</td>
                        <td className="text-right">
                          GHS {sale.outstanding_balance}
                        </td>
                        <td className="text-capitalize text-center">
                          {sale.payment_status === "Full Payment" ? (
                            <span className="badge badge-soft-success">
                              {sale.payment_status}
                            </span>
                          ) : (
                            <span className="badge badge-soft-danger">
                              {sale.payment_status}
                            </span>
                          )}
                        </td>
                        <td>
                          <div className="btn--container justify-content-center">
                            <Link
                              className="action-btn btn--primary btn-outline-primary"
                              to={`/sales/${sale?.id}/details`}
                            >
                              <MdOutlineRemoveRedEye />
                            </Link>

                            <Link
                              className="action-btn btn--danger btn-outline-danger"
                              onClick={() => deleteSale(sale.id)}
                            >
                              <MdDeleteOutline />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {saleHistory.length <= 0 && !loadingSaleHistory && <NoData />}
            </div>

            {/* LIST TO PRINT */}
            <div style={{ display: "none" }}>
              <table
                className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                style={{ width: "100%" }}
                ref={salesListRef}
              >
                <thead className="thead-light">
                  <tr>
                    <th className="">SL</th>
                    <th className="table-column-pl-0">Invoice ID</th>
                    <th>Date</th>
                    <th>Customer</th>
                    <th className="text-right">Total amount</th>
                    <th className="text-right">Amount Paid</th>
                    <th className="text-right">Outstanding balance</th>
                    <th>
                      <div className="text-center">Payment Status</div>
                    </th>
                  </tr>
                </thead>

                <tbody id="set-rows">
                  {saleHistory.map((sale, index) => (
                    <tr key={index} className="status-delivered class-all">
                      <td className="">{index + 1}</td>
                      <td className="table-column-pl-0">{sale.sale_id}</td>
                      <td>{sale.date}</td>

                      <td>
                        {!sale?.customer ? (
                          "Walk in customer"
                        ) : (
                          <>
                            <div>
                              <a
                                className="text-body text-capitalize font-medium"
                                href=""
                              >
                                {sale?.customer?.customer_name}
                              </a>
                            </div>
                            <div className="text-sm">
                              <Link
                                to={`tel:${sale?.customer?.customer_phone_number}`}
                              >
                                {sale?.customer?.customer_phone_number}
                              </Link>
                            </div>
                          </>
                        )}
                      </td>

                      <td className="text-right">GHS {sale.total_cost}</td>
                      <td className="text-right">GHS {sale.amount_paid}</td>
                      <td className="text-right">
                        GHS {sale.outstanding_balance}
                      </td>
                      <td className="text-capitalize text-center">
                        {sale.payment_status === "Full Payment" ? (
                          <span className="badge badge-soft-success">
                            {sale.payment_status}
                          </span>
                        ) : (
                          <span className="badge badge-soft-danger">
                            {sale.payment_status}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer border-0">
            <div className="d-flex justify-content-center justify-content-sm-end">
              {/* PAGINATION */}
              <nav
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p>
                  Page {currentPage} of {totalPages}
                </p>
                <ul className="pagination">
                  <li
                    className={!prevPage ? "page-item disabled" : "page-item"}
                    aria-label="« Previous"
                  >
                    <a
                      onClick={handlePreviousPage}
                      className="page-link"
                      aria-hidden="true"
                    >
                      ‹
                    </a>
                  </li>
                  {renderPageNumbers()}

                  <li
                    className={!nextPage ? "page-item disabled" : "page-item"}
                  >
                    <a
                      className="page-link"
                      onClick={handleNextPage}
                      rel="next"
                      aria-label="Next »"
                    >
                      ›
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SalesHistory;
